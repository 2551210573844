/*=== MIXINS ===*/
/*=== VARIABLES ===*/
/*=== MEDIA QUERY ===*/

.template5 {
  h3 a:hover,
  a,
  .secotionTitle h2 span,
  .tabCommon.singleTab .tab-pane h4,
  .tabCommon.singleTab .tab-pane .blogCommnets .media-body .btn-link,
  .navbar-default .navbar-nav > li > a:hover,
  .navbar-default .navbar-nav > li.active a,
  .navbar-default .navbar-nav > li.active a:hover,
  .navbar-default .navbar-nav > li.open > a,
  .navbar-default .navbar-nav > li.open > a:hover,
  .navbar-default .navbar-nav > li.open > a:focus,
  .navbar-nav li.dropdown.singleDrop .dropdown-menu li a:hover,
  .navbar-nav li.dropdown.singleDrop .dropdown-menu li a:focus,
  .navbar-nav li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a:hover,
  .navbar-nav li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a:focus,
  .main-slider .owl-carousel .owl-controls .owl-nav .owl-prev,
  .main-slider .owl-carousel .owl-controls .owl-nav .owl-next,
  .main-slider .owl-carousel .owl-controls .owl-dot,
  .useLink ul li a:hover,
  .copyRight .list-inline li a:hover,
  .copyRightText p a:hover,
  .tabTop .nav li a:hover,
  .tabTop .nav li.active a,
  .varietyContent h4,
  .offerContent .offerPrice h5,
  #productSlider .carousel-control.left,
  #productSlider .carousel-control.right,
  .productInfo h4,
  .reviewInfo h4,
  .holdingInfo ul li i,
  .homeGallery ul.filter > li > a:hover,
  .homeGallery ul.filter > li.active > a,
  .expertBox:hover .expertCaption h2,
  .cartInfo .panel-heading span,
  .cartInfo table tr td .price,
  .cardTitle i.fa,
  .totalAmount strong,
  .confirm-detail h3 span,
  .confirm-detail .cart-total span,
  .productPrice,
  .sidebar .side-nav li a:hover, .sidebar .side-nav li a:focus,
  .nav-stacked li a:hover, .nav-stacked li a:focus,
  .nav-stacked li.active a,
  .nav-stacked li.active a:hover, .nav-stacked li.active a:focus,
  .nav-stacked li .dropdown-menu li a:hover,
  .nav-stacked li .dropdown-menu li a:focus,
  .nav-stacked li .dropdown-menu li.active a,
  .nav-stacked li .dropdown-menu li.active a:hover,
  .nav-stacked li .dropdown-menu li.active a:focus,
  .singleProductInfo h3,
  .category li a:hover,
  .productSideBar .productSide li a:hover,
  .productSideBar .productSide li a:focus,
  .productCaption h3,
  .profilePersonal .list-unstyled li i.fa,
  .blogPost h2 a,
  .blogCommnets .media-body .btn-link,
  .blogSidebar .categoryList li a:hover,
  .innerWrapper h3 span,
  .innerWrapper .table-responsive .table tbody tr td:first-child,
  .reviewInfo .fa-quote-left,
  .custom-categories .custom-accordion .custom-panel h5,
  .custom-categories .custom-accordion .custom-panel .custom-panel-body a:hover,
  .main-nav .dropdown-menu a:hover,
  .main-nav .nav-item.show a.nav-link,
  .main-nav .dropdown-menu li.dropdown.show > a,
  .tabTop .nav li a.active,
  .custom-categories .custom-accordion .custom-panel h5:hover,
  .innerWrapper .table-responsive .table tbody th:first-child,
  .productSection .owl-nav .owl-prev:before,
  .productSection .owl-nav .owl-next:before {
    color: #ec5598;
  }

  .main-nav .nav-item a.nav-link:hover {
    color: #ec5598 !important;
  }

  @media (min-width: 768px) {
    .navbar-default .navbar-nav > li > a:hover,
    .navbar-nav li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a:hover,
    .navbar-nav li.dropdown.singleDrop .dropdown-menu li a:hover {
      color: #ec5598;
    }
  }

  .countrySelect .sbOptions a:hover,
  .custom-categories .custom-accordion .custom-panel h5:hover {
    color: #ec5598;
  }

  .btn-primary, .btn-primary:hover, .btn-primary:focus,
  .btn-primary.active, .btn-primary:active,
  .btn-secondary:hover, .btn-secondary:focus,
  .btn-secondary.active, .btn-secondary:active,
  .btn-primary.active.focus,
  .btn-primary.active:focus,
  .btn-primary.active:hover,
  .btn-primary:active.focus,
  .btn-primary:active:focus,
  .btn-primary:active:hover,
  .open > .dropdown-toggle.btn-primary.focus,
  .open > .dropdown-toggle.btn-primary:focus,
  .open > .dropdown-toggle.btn-primary:hover,
  .backToTop,
  .btn-theme, .btn-theme:focus,
  .btn-theme.active, .btn-theme:active, .btn-theme:hover,
  .paginationCommon .pagination li a:hover,
  .paginationCommon .pagination li.active a,
  .paginationCommon .pagination li.active a:focus,
  .paginationCommon .pagination li.active a:hover,
  .collapse-style2 .navbar-collapse.content-collapse ul.navbar-nav li.active a,
  .tabCommon.tabThree .nav-tabs li a:before,
  .tabCommon.singleTab .nav-tabs li a:hover,
  .tabCommon.singleTab .nav-tabs li.active a,
  .modalCommon .modal-header,
  .sticker,
  .pagerArea > .pager li a,
  .top-info-bar,
  .navbar-default .navbar-toggle,
  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover,
  .cart_btn a span,
  .footerText .input-group-addon,
  .productImage a:hover i,
  .priceTag,
  .expertSection .carousel-indicators li.active,
  .comingInner .input-group-addon,
  .cartInfo table tr td .btn:hover,
  .cartInfo table tr td .btn:focus,
  .productSideBar .panel-heading,
  .price-slider-inner .ui-widget-content .ui-slider-range,
  .productPagination.productPagination .pagination li.active a,
  .productPagination.productPagination .pagination li.active a:focus,
  .productPagination.productPagination .pagination li.active a:hover,
  .progressSingle .progress-bar,
  .blogSidebar .panel-heading,
  .dashboard.list-inline li .btn-lg:hover,
  .innerWrapper .table-responsive .table thead tr th,
  .innerWrapper .table-responsive .table tbody tr td .btn-default:hover,
  .callAction,
  .custom-categories h4,
  .morphing-btn-clone,
  .fancybox-morphing .fancybox-bg,
  .main-nav .dropdown-menu:before,
  .noUi-connect,
  .tabCommon.singleTab .nav-tabs li a.active,
  .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle,
  .imagediv .showonhover,
  .expertSection .owl-theme .owl-dots .owl-dot.active span, .expertSection .owl-theme .owl-dots .owl-dot:hover span {
    background-color: #ec5598 !important;
    border-color: #ec5598 !important;
  }

  ::-moz-selection {
    background-color: #ec5598 !important;
  }

  ::selection {
    background-color: #ec5598 !important;
  }

  .btn-theme, .btn-theme:focus,
  .btn-theme.active, .btn-theme:active, .btn-theme:hover,
  .paginationCommon .pagination li a:hover,
  .paginationCommon .pagination li.active a,
  .paginationCommon .pagination li.active a:focus,
  .paginationCommon .pagination li.active a:hover,
  .tabCommon.singleTab .nav-tabs li a:hover,
  .tabCommon.singleTab .nav-tabs li.active a,
  .navbar-default .navbar-toggle,
  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover,
  .footerText .form-control:focus,
  .footerText .input-group-addon,
  .expertSection .carousel-indicators li,
  .comingInner .form-control:focus,
  .comingInner .input-group-addon,
  .cartInfo .form-control:focus,
  .cartInfo .input-group-addon,
  .productSideBar .panel-heading,
  .price-slider-inner .ui-widget-content .ui-slider-handle,
  .productPagination.productPagination .pagination li.active a,
  .productPagination.productPagination .pagination li.active a:focus,
  .productPagination.productPagination .pagination li.active a:hover,
  .blogSidebar .panel-heading,
  .innerWrapper .table-responsive .table tbody tr td .btn-default:hover,
  .noUi-handle,
  .tabCommon.singleTab .nav-tabs li a.active,
  .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle,
  .expertSection .owl-theme .owl-dots .owl-dot span {
    border-color: #ec5598 !important;
  }

  @media (min-width: 768px) {
    .navbar .navbar-nav li.open .dropdown-menu,
    .navbar .navbar-nav li.dropdown .dropdown-menu {
      border-top-color: #ec5598 !important;
    }
  }

  .footerInfo {
    border-top-color: #ec5598 !important;
  }

  .tabs-left .nav li a.active,
  .owl-stage-outer,
  .tabs-left .nav li a:hover,
  .tabs-left .nav li.active a,
  .tabs-left .nav li.active a:hover,
  .tabs-left .nav li.active a:focus,
  .tabs-left .nav li.active a a:active,
  .homeGallery ul.filter > li.active > a,
  .homeGallery ul.filter > li > a:hover {
    border-bottom-color: #ec5598 !important;
  }

  .tabTop .nav li a.active span,
  .tabTop .nav li.active a span,
  .tabTop .nav li a:hover span {
    border-bottom-color: rgba(236, 85, 152, 0.5) !important;
  }

  .offerContent .offerTitle,
  .productMask {
    background-color: rgba(236, 85, 152, 0.7) !important;
  }

  .expertMask, .priceTableWrapper:hover .maskImage,
  .fancybox-bg {
    background-color: rgba(236, 85, 152, 0.5) !important;
  }

  .footerText .footerLogo .fill-primary {
    fill: #ec5598 !important;
  }
}