.menuTemplate {
    display: flex;
    width: 100%;
    .mainHeaderComponent {
        width: 100%;
        height: 10vh;
        // z-index: 6
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: rgb(191, 191, 191);

        position: fixed;
        ul {
            margin: 0;
            padding: 0;
            margin-right: 2em;
            opacity: 1;
            z-index: 111111111;
            li {
                list-style-type: none; } }
        // header
        //     background: red
 }        //     margin-left: 20px

    .MuiPaper-root {
        width: 14%; }
    .mainLogo {
        height: 13%;
        padding: 3%; } }
