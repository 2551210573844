.side-bar {
  height: 100%;
  width: 0;
  position: absolute;
  z-index: 1;
  background-color: #5C0000;
  overflow-x: hidden;
  font-size: 20px;
  color: white;
  .container {
    padding: 20px 0 0 15px;
  }
  .title {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .box-wrapper {
    display: flex;
    align-items: center;
    .search-input {
      position: relative;
      font-size: 16px;
      max-width: 230px;
    }
    i {
      position: relative;
      left: -22px;
      color: #5c0000;
    }
    .box {
      width: 20px;
      height: 20px;
      margin-right: 7px;
    }
    .box-label {
      font-size: 20px;
      overflow: hidden;
    }
  }
}

@media (max-width: 1280px) {
  .side-bar {
    font-size: 17px;
    .container {
      padding-left: 7px;
    } 
    .box-wrapper {
      .box {
        width: 16px;
        height: 18px;
        margin-right: 5px;
      }
      .box-label {
        font-size: 16px;
      }
      .search-input {
        font-size: 15px;
        max-width: 170px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .side-bar {
    font-size: 15px;
    .container {
      padding-left: 5px;
    }
    .box-wrapper {
      .box {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
      .box-label {
        font-size: 16px;
      }
      .search-input {
        font-size: 14px;
        max-width: 130px;
      }
    }
  }
}

@media (max-width: 960px) {
  .side-bar {
    .box-wrapper {
      .box {
        width: 13px;
        height: 13px;
        margin-right: 4px;
      }
      .box-label {
        font-size: 13px;
      }
      .search-input {
        font-size: 12px;
        max-width: 100px;
      }
    }
  }
}

@media (max-width: 768px) {
  .side-bar {
    font-size: 13px;
    .container {
      padding-left: 3px;
    }
    .box-wrapper {
      .box {
        width: 11px;
        height: 11px;
        margin-right: 2px;
      }
      .box-label {
        font-size: 11px;
      }
      .search-input {
        font-size: 12px;
        max-width: 100px;
      }
    }
  }
}


@media only screen and (max-device-width : 640px) {
  /* Styles */
  .side-bar {
    font-size: 13px;
    .container {
      padding-left: 3px;
    }
    .title {
      margin-bottom: 5px;
    }
    .box-wrapper {
      .search-input {
        font-size: 12px;
        max-width: 93px;
      }
      .box {
        width: 15px;
        height: 15px;
        margin-right: 2px;
      }
    }
  }
}