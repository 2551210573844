.sideTab {
  padding-left: 220px;
  padding-top: 50px;
}

.mainBorderStyle {
  border: 1px solid #d3d3d3;
  padding: 1rem;
  padding-right: 4rem;
}

.sidebarContent {
  padding: 0.5rem;
  border: 1px solid #d3d3d3;
  border-right: 0px;
  display: table-cell;
}
.sidebarContent:last-child {
  border-right: 1px solid #d3d3d3;
}
.action-cell {
  width: 100px;
}
.bookingTable {
  background-color: #ebebeb;
  width: 100%;
}

.bookingTable tr {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.tableButton {
  background-color: #ff3600;
  color: #ffffff;
  border: none;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 0.5rem;
  margin-top: 20px;
}

.emailButton {
  background-color: #ff3600;
  color: #ffffff;
  border: none;
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 0.5rem;
  margin-top: 20px;
}

.lineBox {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.sideBar {
  border: 1px solid #d3d3d3;
  height: 515px;
}

.mainBookingBorder {
  border: 1px solid #d3d3d3;
  padding: 20px 0px;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .sideTab {
    padding-left: 10px;
  }
  #mainProfile {
    text-align: center;
  }
  .sideBar {
    height: 250px;
  }
  .mainBorderStyle {
    margin-left: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .sideTab {
    padding-left: 20px;
  }
  .sidebarContent {
    padding-right: 0px;
  }
  #contentSection {
    margin-left: 0px;
  }
}
