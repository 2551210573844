.content-container {
  padding: calc(1rem + 8px);
  padding-bottom: 54px;
  padding-top: calc(1rem);
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .content-container {
    padding: 6px !important;
  }
}

.full-height {
    height: 100%;
}

.booking-search-bar-icon {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 9px;
}

.category-pill, .primary-header {
  background-color: #002147;
  color: white;
}

.table thead { 
  background-color: #002147;
}

.table thead th, .table thead th span { 
  color: white !important;
}

.table tbody tr {
  background-color: #FFF;
}

.view-icon {
  border-radius: 5rem;
  width: 25px !important;
  height: 25px;
  padding: 4px;
  background-color: rgb(23, 87, 34);
  margin: 0 4px;
  cursor: pointer;
}
.swal2-container  {
  z-index: 1303 !important;
}
.routes-main-content {
  min-height: calc(100vh - 92px);
}
.primary-button {
  background-color: #ff3600 !important;
  color: white !important;
}
.MuiPopover-root {
  z-index: 1306 !important;
}
.employee-service-card-wrapper {
  min-width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1302;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
}
.MuiFormControl-root {
  width:100%;
}
.light-border {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
}
.light-border-t {
  border-top: 1px solid rgba(0, 0, 0, 0.3) !important;
}
.light-border-x {
  border-left: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.3) !important;
}
.light-border-b {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;
}
.MuiAutocomplete-popper {
    z-index: 1500 !important;
    animation: none !important;
    transition: none !important;
}

.half-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .half-container {
    max-width: 270px;
  }
}

@media (min-width: 768px) {
  .half-container {
    max-width: 360px;
  }
}

@media (min-width: 992px) {
  .half-container {
    max-width: 480px;
  }
}

@media (min-width: 1200px) {
  .half-container {
    max-width: 570px;
  }
}

.module-selector {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
}

.module-selector-popover {
  transform-origin: 0px !important;
  left: 0px !important;
}

.table.table-bordered tr {
  border-width: 0 1px 1px 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.menu {
  position: absolute;
  width: 100%;
  max-width: 100%;
  padding-right: 0px !important;
  z-index: 100;
  background-color: white!important;
  border: 1px solid rgba(0, 0, 0, .15)
}