@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");

*,
body {
  font-family: "Lato", sans-serif !important;
  transition: 0.3s !important;
}

.btn-postitive {
  color: #fff !important;
  background-color: green !important;
  border-color: green !important;
  text-transform: uppercase;
}
