
.template5 {
  * {
    outline: 0;
    margin: 0;
    padding: 0;
  }

  html, * {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
    font-weight: 400;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  ul li {
    list-style-type: none;
  }

  p {
    color: #666666;
    font-weight: 400;
    margin-bottom: 15px;
  }

  @media (min-width: 320px) {
    p {
      font-size: 13px;
      line-height: 23px;
      margin-bottom: 25px;
    }
  }

  @media (min-width: 768px) {
    p {
      font-size: 14px;
      line-height: 26px;
      margin-bottom: 25px;
    }
  }

  h1, h2, h3, h4, h5 {
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    margin-top: 0;
    font-weight: 700;
    color: #222222;
  }

  h2 {
    font-size: 19px;
    margin-bottom: 25px;
    line-height: 33px;
  }

  @media (min-width: 480px) {
    h2 {
      font-size: 24px;
    }
  }

  h3 {
    font-size: 15px;
    margin-bottom: 15px;
  }

  @media (min-width: 480px) {
    h3 {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  @media (min-width: 768px) {
    h3 {
      font-size: 15px;
      margin-bottom: 15px;
    }
  }

  @media (min-width: 992px) {
    h3 {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  h3 a {
    text-transform: capitalize;
    color: #222222;
  }

  h3 a:hover {
    color: #ec5598;
  }

  h4 {
    font-size: 18px;
    margin-bottom: 20px;
    text-transform: capitalize;
  }

  h5 {
    font-size: 14px;
    text-transform: capitalize;
  }

  /*=== 1.COMMON ===*/
  a {
    color: #ec5598;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
  }

  a:hover, a:focus {
    outline: 0;
    text-decoration: none;
  }

  .top-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0px 12px 0px 12px;
  }

  .divider {
    border-top: 4px solid #ec5598;
  }

  .main-wrapper {
    position: relative;
  }

  .form-control:focus {
    box-shadow: none;
  }

  .secotionTitle {
    text-align: center;
  }

  @media (min-width: 320px) {
    .secotionTitle {
      padding: 50px 0 30px 0;
    }
  }

  @media (min-width: 768px) {
    .secotionTitle {
      padding: 100px 0 80px 0;
    }
  }

  .secotionTitle h2 {
    line-height: 40px;
    color: #222222;
    margin: 0;
  }

  @media (min-width: 320px) {
    .secotionTitle h2 {
      font-size: 16px;
      letter-spacing: 4px;
    }
  }

  @media (min-width: 768px) {
    .secotionTitle h2 {
      font-size: 24px;
      letter-spacing: 10px;
    }
  }

  .secotionTitle h2 span {
    display: block;
    letter-spacing: normal;
    color: #ec5598;
    font-family: 'Herr Von Muellerhoff', cursive;
    font-size: 90px;
    line-height: 42px;
    text-transform: capitalize;
    font-weight: 400;
  }

  .patternbg {
    // background-image: url(../img/home/pattern-1.jpg);
    background-attachment: fixed;
    width: 100%;
    background-size: cover;
    background-position: center center;
  }

  ::-moz-selection {
    background-color: #ec5598;
    color: #ffffff;
  }

  ::selection {
    background-color: #ec5598;
    color: #ffffff;
  }

  .padding {
    padding: 100px 0 70px !important;
  }

  .img-responsive {
    max-width: 100%;
    height: auto;
  }

  /* Lazy Load Image */
  img {
    transition: -webkit-filter 0.3s;
    transition: filter 0.3s;
    transition: filter 0.3s, -webkit-filter 0.3s;
  }

  img.lazyestload {
    width: 100%;
    -webkit-filter: blur(8px);
            filter: blur(8px);
  }

  .card {
    border: none;
    border-radius: 0;
  }

  .list-inline > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }

  .cursor-default {
    cursor: default !important;
  }

  /*=== 2.OPTIONS ===*/
  .main-wrapper {
    background-color: #ffffff;
  }

  body.bodyColor.default {
    background-color: #f5f5f5;
  }

  body.bodyColor.pattern-01 {
    // background-image: url("../img/patterns/001.png");
    background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
  }

  body.bodyColor.pattern-02 {
    // background-image: url("../img/patterns/002.png");
    background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
  }

  body.bodyColor.pattern-03 {
    // background-image: url("../img/patterns/003.png");
    background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
  }

  body.bodyColor.pattern-04 {
    // background-image: url("../img/patterns/004.png");
    background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
  }

  body.bodyColor.pattern-06 {
    // background-image: url("../img/patterns/006.png");
    background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
  }

  body.bodyColor.pattern-07 {
    // background-image: url("../img/patterns/007.png");
    background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
  }

  body.bodyColor.pattern-05 {
    // background-image: url("../img/patterns/005.png");
    background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
  }

  body.bodyColor .main-wrapper {
    overflow-x: hidden;
  }

  body.bodyColor.wrapper {
    margin: 0 auto;
    box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 480px) {
    body.bodyColor.wrapper {
      max-width: 450px;
    }
  }

  @media (min-width: 768px) {
    body.bodyColor.wrapper {
      max-width: 750px;
    }
  }

  @media (min-width: 992px) {
    body.bodyColor.wrapper {
      max-width: 970px;
    }
  }

  @media (min-width: 1200px) {
    body.bodyColor.wrapper {
      max-width: 1170px;
      margin: 0 auto;
    }
  }

  @media (min-width: 480px) {
    body.bodyColor .header, body.bodyColor .navbar-sticky {
      max-width: 450px;
    }
  }

  @media (min-width: 768px) {
    body.bodyColor .header, body.bodyColor .navbar-sticky {
      max-width: 750px;
    }
  }

  @media (min-width: 992px) {
    body.bodyColor .header, body.bodyColor .navbar-sticky {
      max-width: 970px;
    }
  }

  @media (min-width: 1200px) {
    body.bodyColor .header, body.bodyColor .navbar-sticky {
      max-width: 1170px;
    }
  }

  body.bodyColor .navbar-sticky {
    margin: 0 auto;
  }

  .navbar .container {
    position: relative;
  }

  .static .bb-fixed-header {
    display: none;
  }

  .navbar.bb-fixed-header {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    z-index: 999;
    transition: all 0.3s ease-in-out;
  }

  .navbar.bb-fixed-header .navbar-brand {
    top: 0;
    width: 120px;
    padding: 10px 40px;
  }

  .bb-fixed-header.navbar-default .navbar-nav > li > a {
    padding: 30px 10px;
  }

  @media (min-width: 992px) {
    .bb-fixed-header.navbar-default .navbar-nav > li > a {
      padding: 30px 30px;
    }
  }

  /*=== 2. UI KIT ===*/
  /*=== 2.1 BUTTON ===*/
  .btn {
    font-family: 'Open Sans', sans-serif;
    line-height: 15px;
    padding: 12px 0;
    color: #ffffff;
    font-weight: 700;
    border: none;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 0;
    letter-spacing: 1px;
  }

  @media (min-width: 768px) {
    .btn {
      padding: 13px 0;
      font-size: 12px;
    }
  }

  @media (min-width: 992px) {
    .btn {
      padding: 15px 0;
      font-size: 14px;
    }
  }

  .btn:focus, .btn.active, .btn:active, .btn:hover {
    border: none;
    outline: 0;
    color: #ffffff;
    box-shadow: none !important;
  }

  button {
    outline: 0 !important;
  }

  .waves-button, .waves-button:hover,
  .waves-button:visited, .waves-button-input {
    font-size: 11px;
    line-height: 11px;
    color: #ffffff;
  }

  @media (min-width: 768px) {
    .waves-button, .waves-button:hover,
    .waves-button:visited, .waves-button-input {
      font-size: 12px;
    }
  }

  .btn-primary {
    background-color: #ec5598;
    transition: all 0.3s ease-in-out;
  }

  .btn-primary > i {
    margin-left: 10px;
  }

  .btn-primary:hover {
    background-color: #ec5598;
  }

  .btn-primary:focus, .btn-primary.active, .btn-primary:active {
    background-color: #ec5598;
  }

  .btn-primary.btn-Full {
    float: right;
    width: 100%;
  }

  .btn-secondary {
    transition: all 0.3s ease-in-out;
    background-color: #eaeaea;
  }

  .btn-secondary > i {
    margin-left: 10px;
  }

  .btn-secondary:hover {
    background-color: #ec5598;
  }

  .btn-secondary:focus, .btn-secondary.active, .btn-secondary:active {
    background-color: #ec5598;
  }

  .btn-border {
    border: 2px solid #ffffff;
    padding: 10px 0;
  }

  @media (min-width: 768px) {
    .btn-border {
      padding: 11px 0;
    }
  }

  @media (min-width: 992px) {
    .btn-border {
      padding: 13px 0;
    }
  }

  .btn-border:hover, .btn-border:focus {
    border: 2px solid #ffffff;
  }

  .btn-border > i {
    margin-left: 10px;
  }

  .btn-primary.active.focus,
  .btn-primary.active:focus,
  .btn-primary.active:hover,
  .btn-primary:active.focus,
  .btn-primary:active:focus,
  .btn-primary:active:hover,
  .open > .dropdown-toggle.btn-primary.focus,
  .open > .dropdown-toggle.btn-primary:focus,
  .open > .dropdown-toggle.btn-primary:hover {
    border: none;
    background-color: #ec5598;
    outline: 0;
  }

  .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
    background-color: #ec5598;
    border-color: #ec5598;
  }

  .btn-primary.callBtn:not(:disabled):not(.disabled).active, .btn-primary.callBtn:not(:disabled):not(.disabled):active, .show > .btn-primary.callBtn.dropdown-toggle {
    background-color: #ffffff;
    border-color: #ffffff;
    color: black;
  }

  .empty {
    text-indent: -999px;
  }

  .backToTop {
    position: absolute;
    right: 50%;
    top: -25px;
    width: 50px;
    height: 50px;
    text-align: center;
    background-color: #ec5598;
    border-radius: 100%;
    display: block;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: translate(50%, 0);
  }

  .backToTop i {
    font-size: 35px;
    font-weight: bold;
    line-height: 45px;
    color: #ffffff;
  }

  .backToTop:hover i {
    color: #ffffff;
  }

  .buttonPart {
    border-bottom: 1px solid #dedede;
  }

  @media (min-width: 320px) {
    .buttonPart {
      padding: 0 0 20px 0;
    }
  }

  @media (min-width: 768px) {
    .buttonPart {
      padding: 0 0 70px 0;
    }
  }

  .squareBtn, .fullRoundBtn, .roundBtn {
    margin: 0 0 30px 0;
    text-align: center;
  }

  .btn-common {
    color: #222222;
    border: 1px solid #dedede;
    padding: 14px 38px;
    background-color: transparent;
    border-radius: 0;
    letter-spacing: 1px;
  }

  .btn-common:focus, .btn-common.active, .btn-common:active, .btn-common:hover {
    color: #222222;
    border: 1px solid #dedede;
  }

  @media (min-width: 320px) {
    .btn-common {
      margin: 0 auto 20px auto;
      padding: 14px 38px;
      font-size: 14px;
    }
  }

  @media (min-width: 768px) {
    .btn-common {
      margin: 0;
      padding: 14px;
      font-size: 13px;
    }
  }

  @media (min-width: 992px) {
    .btn-common {
      padding: 14px 37px;
      font-size: 14px;
    }
  }

  @media (min-width: 768px) {
    .btn-common-element {
      font-size: 9px;
    }
  }

  @media (min-width: 992px) {
    .btn-common-element {
      padding: 14px 25px;
      font-size: 12px;
    }
  }

  @media (min-width: 1200px) {
    .btn-common-element {
      padding: 14px 38px;
      font-size: 14px;
    }
  }

  .btn-theme {
    background-color: #ec5598;
    border-color: #ec5598;
    color: #ffffff;
  }

  .btn-theme:focus, .btn-theme.active, .btn-theme:active, .btn-theme:hover {
    background-color: #ec5598;
    border-color: #ec5598;
    color: #ffffff;
  }

  .btn-gray {
    background-color: #f5f5f5;
  }

  .btn-gray:focus, .btn-gray.active, .btn-gray:active, .btn-gray:hover {
    background-color: #f5f5f5;
  }

  .btn-dark {
    background-color: #444548;
    border-color: #444548;
    color: #ffffff;
  }

  .btn-dark:focus, .btn-dark.active, .btn-dark:active, .btn-dark:hover {
    background-color: #444548;
    border-color: #444548;
    color: #ffffff;
  }

  .btn-full-round {
    border-radius: 25px;
  }

  .btn-round {
    border-radius: 4px;
  }

  /*=== 2.2 SECTION ===*/
  .tabSection {
    background-color: #ffffff;
  }

  .tabSection hr {
    border-color: #dedede;
  }

  @media (min-width: 320px) {
    .tabSection hr {
      margin: 0 0 50px 0;
    }
  }

  @media (min-width: 768px) {
    .tabSection hr {
      margin: 0 0 100px 0;
    }
  }

  @media (min-width: 320px) {
    .tabSection {
      padding: 50px 15px 0 15px;
    }
  }

  @media (min-width: 768px) {
    .tabSection {
      padding: 100px 15px 0 15px;
    }
  }

  .uiTitle h2 {
    letter-spacing: 2px;
    margin: 0 0 25px 0;
  }

  .paginationSection {
    padding: 0 0 70px 0;
  }

  @media (min-width: 320px) {
    .paginationSection {
      padding: 0 15px 50px 15px;
    }
  }

  @media (min-width: 768px) {
    .paginationSection {
      padding: 0 15px 70px 15px;
    }
  }

  @media (min-width: 320px) {
    .formArea {
      padding: 50px 0;
    }
  }

  @media (min-width: 768px) {
    .formArea {
      padding: 100px 0;
    }
  }

  .pageTitleArea {
    width: 100%;
    background-size: cover;
    background-position: center top;
    padding-bottom: 0;
    position: relative;
    padding: 105px 0;
    background-repeat: no-repeat;
  }

  .pageTitle h1 {
    margin: 0;
    text-align: center;
    font-size: 55px;
    line-height: 60px;
    color: #ffffff;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
  }

  @media (min-width: 320px) {
    .pageTitle h1 {
      font-size: 40px;
    }
  }

  @media (min-width: 768px) {
    .pageTitle h1 {
      font-size: 55px;
    }
  }

  /*=== 2.3 FORM ===*/
  .form-group {
    margin-bottom: 20px;
  }

  .form-group button {
    width: 172px;
  }

  .form-control {
    height: 43px;
    box-shadow: none;
    border-radius: 0;
    padding-right: 18px;
    border-color: #dedede;
    color: #666666;
    font-size: 14px;
  }

  .form-control:focus {
    border-color: #dedede;
  }

  .dateSelect {
    margin-bottom: 20px;
  }

  .dateSelect .input-group-addon {
    background-color: transparent;
    border-color: #dedede;
    border-radius: 0;
  }

  .filterDate {
    position: relative;
  }

  .filterDate span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    z-index: 10;
    margin-top: -1px;
  }

  .custom-dateSelect {
    margin-bottom: 35px !important;
  }

  textarea.form-control {
    height: 150px;
  }

  .formPart {
    border-radius: 0;
    border-color: #dedede;
    border: none;
  }

  .formPart .panel-heading {
    border-radius: 0;
    height: 56px;
    padding: 0 30px;
    line-height: 56px;
    font-weight: 400;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    color: #222222;
    letter-spacing: 1px;
    border-bottom: none;
  }

  @media (min-width: 320px) {
    .formPart .panel-heading {
      font-size: 14px;
    }
  }

  @media (min-width: 768px) {
    .formPart .panel-heading {
      font-size: 20px;
    }
  }

  .formPart .panel-heading span {
    font-weight: 700;
  }

  .formPart .panel-body {
    padding: 30px;
    border: 1px solid #dedede;
    border-top: none;
  }

  .formPart .form-group {
    position: relative;
  }

  .formPart .form-control {
    padding-right: 38px;
  }

  .formPart i.fa {
    position: absolute;
    right: 0;
    top: 0;
    width: 38px;
    height: 100%;
    text-align: center;
    line-height: 44px;
    color: #666666;
    color: #999;
  }

  .formPart .checkbox {
    margin: 0 0 20px 0;
  }

  .formPart .checkbox label {
    font-size: 13px;
    color: #000000;
  }

  /*=== CUSTOM CHECKBOX  ===*/
  .custom-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  .custom-list .custom-checkbox {
    display: inline-block !important;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 15px;
    padding-bottom: 10px;
  }

  .custom-list .custom-checkbox label {
    text-transform: capitalize;
  }

  .custom-list .form-check-input {
    margin-top: .25em;
  }

  /*=== CUSTOM SELECTBOX  ===*/
  .custom-timeSelect {
    margin-bottom: 35px;
  }

  .timeSelect {
    position: relative;
    margin-bottom: 0 !important;
  }

  .timeSelect.appointment-timeSelect {
    margin-bottom: 20px !important;
  }

  @media (min-width: 768px) {
    .timeSelect.appointment-timeSelect {
      float: left;
    }
  }

  .timeSelect .sbToggle {
    background: transparent;
  }

  .timeSelect .sbToggle::before {
    position: absolute;
    content: '\f017';
    font-family: 'FontAwesome';
    font-style: normal;
    left: 0;
    top: 50%;
    color: #848484;
    transform: translateY(-50%);
  }

  /*=== FANCYBOX MORPHING ===*/
  .morphing-content {
    display: none;
  }

  #morphing-content {
    width: 100%;
  }

  #morphing-content .morphing-searchBox {
    margin-top: 0;
    padding-top: 0;
  }

  #morphing-content .morphing-searchBox h2 {
    color: #ffffff;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 60px;
  }

  #morphing-content .morphing-searchBox .row {
    display: flex;
    justify-content: center;
  }

  #morphing-content .morphing-searchBox .fancymorphingForm {
    overflow: inherit;
  }

  #morphing-content .morphing-searchBox .fancymorphingForm .input-group {
    width: 100%;
    position: relative;
  }

  #morphing-content .morphing-searchBox .fancymorphingForm .input-group .form-control {
    border-color: #ffffff;
    color: #ffffff;
    background-color: transparent;
  }

  #morphing-content .morphing-searchBox .fancymorphingForm .input-group .form-control::-moz-placeholder {
    color: #ffffff;
  }

  #morphing-content .morphing-searchBox .fancymorphingForm .input-group .form-control:-ms-input-placeholder {
    color: #ffffff;
  }

  #morphing-content .morphing-searchBox .fancymorphingForm .input-group .form-control::-ms-input-placeholder {
    color: #ffffff;
  }

  #morphing-content .morphing-searchBox .fancymorphingForm .input-group .form-control::placeholder {
    color: #ffffff;
  }

  #morphing-content .morphing-searchBox .fancymorphingForm .input-group .input-group-append {
    position: relative;
  }

  #morphing-content .morphing-searchBox .fancymorphingForm .input-group .input-group-append .btn {
    color: #ffffff;
    position: absolute;
    left: 0;
    border: 1px solid transparent;
    z-index: 10;
    height: 43px;
    outline: 0;
    padding: 0 16px 0 13px;
  }

  #morphing-content .morphing-searchBox .fancymorphingForm .input-group .input-group-append .btn:focus, #morphing-content .morphing-searchBox .fancymorphingForm .input-group .input-group-append .btn:active, #morphing-content .morphing-searchBox .fancymorphingForm .input-group .input-group-append .btn.active {
    box-shadow: none;
  }

  .morphing-btn-clone,
  .fancybox-morphing .fancybox-bg {
    background-color: #ec5598;
  }

  /*=== 2.4 PAGINATION ===*/
  .paginationCommon nav {
    border-top: 1px solid #dedede;
  }

  @media (min-width: 320px) {
    .paginationCommon nav {
      margin: 0 0 20px 0;
    }
  }

  @media (min-width: 768px) {
    .paginationCommon nav {
      margin: 0 0 70px 0;
    }
  }

  .paginationCommon .pagination > li > a {
    border: 1px solid #dedede;
  }

  .paginationCommon .pagination {
    margin: 30px 0;
  }

  .paginationCommon .pagination li {
    float: right;
    margin-left: 5px;
  }

  .paginationCommon .pagination li a {
    border-color: #dedede;
    float: none;
    font-size: 15px;
    color: #666666;
    font-weight: 700;
    width: 45px;
    height: 45px;
    line-height: 45px;
    padding: 0;
    display: block;
    text-align: center;
  }

  @media (min-width: 768px) {
    .paginationCommon .pagination li a {
      width: 50px;
      height: 50px;
      line-height: 50px;
    }
  }

  .paginationCommon .pagination li a:hover {
    color: #ffffff;
    background-color: #ec5598;
    border-color: #ec5598;
  }

  .paginationCommon .pagination li a:focus {
    background-color: #ffffff;
    border-color: #dedede;
  }

  .paginationCommon .pagination li.active a, .paginationCommon .pagination li.active a:focus, .paginationCommon .pagination li.active a:hover {
    color: #ffffff;
    background-color: #ec5598;
    border-color: #ec5598;
  }

  .paginationCommon .pagination li:first-child a {
    border-radius: 0;
  }

  .paginationCommon .pagination li:last-child a {
    border-radius: 0;
  }

  .paginationCommon.paginationTwo .pagination li {
    margin-left: -1px;
  }

  .paginationCommon.paginationTwo .pagination li a {
    float: none;
    display: inline-block;
  }

  .paginationCommon.paginationTwo .pagination li:first-child a {
    border-radius: 0 25px 25px 0;
  }

  .paginationCommon.paginationTwo .pagination li:last-child a {
    border-radius: 25px 0 0 25px;
  }

  .paginationCommon.paginationThree .pagination li a {
    border-radius: 100%;
  }

  /*=== 2.5 ACCORDION ===*/
  .accordionCommon .panel {
    margin-bottom: 30px;
    border: none;
  }

  .accordionCommon .panel-heading {
    display: block;
    padding: 15px 20px;
    border-radius: 8px;
    font-weight: 600;
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
    position: relative;
  }

  .accordionCommon .panel-heading span.iconBlock {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 3px;
    background-color: #ffffff;
    color: #ffffff;
    display: inline-block;
    float: left;
    box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  }

  .accordionCommon .panel-heading span.iconBlock.iconTransparent {
    background-color: transparent;
    box-shadow: none;
    color: #ffffff;
  }

  .accordionCommon span.separator {
    width: 1px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.25);
    position: absolute;
    top: 0;
    left: 60px;
  }

  .accordionCommon .panel-body {
    padding: 30px;
    background-color: #ffffff;
    box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0 0 8px 8px;
  }

  .accordionCommon .panel-body p {
    margin-bottom: 35px;
  }

  .accordionCommon .panel-body p:last-child {
    margin-bottom: 0;
  }

  /*=== 2.5 COLLAPSE ===*/
  .navbar-collapse.content-collapse {
    padding-left: 0;
    padding-right: 0;
    margin-top: 10px;
    display: block;
  }

  .navbar-collapse.content-collapse ul.navbar-nav {
    border: 1px solid rgba(0, 0, 0, 0.1);
    float: none;
    height: auto;
    overflow-x: visible;
    background-color: #ffffff;
    transition: height 0.3s ease;
    margin: 0;
  }

  .navbar-collapse.content-collapse ul.navbar-nav li {
    border-bottom: 1px dashed #b7b7b7;
    display: inline-block;
    width: 100%;
    float: none;
  }

  .navbar-collapse.content-collapse ul.navbar-nav li:last-child {
    border-bottom: none;
  }

  .navbar-collapse.content-collapse ul.navbar-nav li a {
    font-size: 14px;
    padding: 20px 25px;
    position: relative;
    font-weight: 700;
    color: #222222;
    text-transform: uppercase;
  }

  .navbar-collapse.content-collapse ul.navbar-nav li a i {
    position: absolute;
    left: 25px;
    font-size: 12px;
    line-height: 20px;
    font-weight: lighter;
    color: rgba(0, 0, 0, 0.6);
  }

  .navbar-collapse.content-collapse ul.navbar-nav li a.collapsed[aria-expanded="true"] {
    margin-bottom: 0;
  }

  .navbar-collapse.content-collapse ul.navbar-nav li a.collapsed {
    margin-bottom: 0;
  }

  .navbar-collapse.content-collapse ul.navbar-nav li a, .navbar-collapse.content-collapse ul.navbar-nav li a:hover, .navbar-collapse.content-collapse ul.navbar-nav li a:focus {
    background: transparent;
  }

  .navbar-collapse.content-collapse ul.navbar-nav li p {
    margin-bottom: 0;
    margin-top: 25px;
  }

  .navbar-collapse.content-collapse ul.navbar-nav li p:first-child {
    margin-top: 0;
  }

  .navbar-collapse.content-collapse .collapse-inner {
    padding: 0 25px 20px 25px;
  }

  .collapse-style1 {
    margin-bottom: 30px;
  }

  @media (min-width: 992px) {
    .collapse-style1 {
      margin-bottom: 0;
    }
  }

  .collapse-style1 li a {
    display: block;
  }

  .collapse-style1 li a.collapse_common_icon2 {
    position: relative;
  }

  .collapse-style1 li a.collapse_common_icon2::before {
    position: absolute;
    content: "\f068";
    font-family: 'FontAwesome';
    left: 25px;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.6);
  }

  .collapse-style1 li a.collapsed::before {
    content: "\f067";
    color: rgba(0, 0, 0, 0.6);
  }

  .collapse-style2 .navbar-collapse.content-collapse ul.navbar-nav {
    border: none;
  }

  .collapse-style2 .navbar-collapse.content-collapse ul.navbar-nav li {
    border: none;
    margin-bottom: 20px;
  }

  .collapse-style2 .navbar-collapse.content-collapse ul.navbar-nav li a {
    // background: url(../img/home/pattern-1.jpg) repeat;
    color: #222222;
    display: block;
  }

  .collapse-style2 .navbar-collapse.content-collapse ul.navbar-nav li a i {
    color: rgba(0, 0, 0, 0.6);
    display: none;
  }

  .collapse-style2 .navbar-collapse.content-collapse ul.navbar-nav li a.collapse_common_icon {
    position: relative;
  }

  .collapse-style2 .navbar-collapse.content-collapse ul.navbar-nav li a.collapse_common_icon::before {
    position: absolute;
    content: "\f068";
    font-family: 'FontAwesome';
    left: 25px;
    font-size: 11px;
  }

  .collapse-style2 .navbar-collapse.content-collapse ul.navbar-nav li a.collapsed::before {
    content: "\f067";
    color: rgba(0, 0, 0, 0.6);
  }

  .collapse-style2 .navbar-collapse.content-collapse ul.navbar-nav li.active a {
    background: #ec5598;
    color: #ffffff;
  }

  .collapse-style2 .navbar-collapse.content-collapse ul.navbar-nav li.active a i {
    color: #ffffff;
  }

  .collapse-style2 .navbar-collapse.content-collapse .collapse-inner {
    padding: 20px 25px 20px 25px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: none;
  }

  .collapse-style2 .navbar-collapse.content-collapse .media:first-child {
    margin-top: 0;
  }

  .custom-panelTwo {
    margin-bottom: 0;
  }

  /*=== 2.6 TABS ===*/
  @media (min-width: 320px) {
    .tabCommon {
      margin: 0 0 50px 0;
    }
  }

  @media (min-width: 768px) {
    .tabCommon {
      margin: 0 0 100px 0;
    }
  }

  .tabCommon .nav-tabs li a {
    border: 1px solid #dedede;
    border-radius: 0;
    background-color: #f7f7f7;
    margin-left: 5px;
    color: #222222;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    text-align: center;
  }

  .tabCommon .nav-tabs li a:hover {
    background-color: #ffffff;
    border-bottom-color: #ffffff;
  }

  @media (min-width: 320px) {
    .tabCommon .nav-tabs li a {
      width: auto;
      padding: 12px 12px;
      font-size: 12px;
    }
  }

  @media (min-width: 768px) {
    .tabCommon .nav-tabs li a {
      width: 131px;
      padding: 12px 0;
      font-size: 14px;
    }
  }

  .tabCommon .nav-tabs li.active a {
    background-color: #ffffff;
    border-bottom-color: #ffffff;
  }

  .tabCommon .tab-content {
    padding: 30px;
    border: 1px solid #dedede;
    border-top: none;
  }

  .tabCommon .tab-pane h3 {
    font-weight: 400;
    margin: 0 0 10px 0;
    font-size: 14px;
  }

  .tabCommon .tab-pane p {
    margin: 0;
  }

  .tabCommon.tabOne .nav-tabs li a {
    display: block;
  }

  .tabCommon.tabOne .nav-tabs li a.active {
    cursor: default;
    background-color: #ffffff;
    border-bottom-color: #ffffff;
  }

  .tabCommon.tabOne .nav-tabs > li {
    margin-bottom: -1px;
  }

  .tabCommon.tabTwo .nav-tabs li a {
    border-radius: 4px 4px 0 0;
    margin: 0 0 0 -1px;
    display: block;
  }

  .tabCommon.tabTwo .nav-tabs li a.active {
    cursor: default;
    background-color: #ffffff;
    border-bottom-color: #ffffff;
  }

  .tabCommon.tabTwo .nav-tabs > li {
    margin-bottom: -1px;
  }

  .tabCommon.tabThree .nav-tabs li a {
    height: 45px;
    line-height: 45px;
    position: relative;
    display: block;
  }

  @media (min-width: 320px) {
    .tabCommon.tabThree .nav-tabs li a {
      padding: 0 12px;
    }
  }

  @media (min-width: 768px) {
    .tabCommon.tabThree .nav-tabs li a {
      padding: 0;
    }
  }

  .tabCommon.tabThree .nav-tabs li a:before {
    content: "";
    background-color: #ec5598;
    height: 3px;
    transform: scale(0);
    position: absolute;
    width: 100%;
    right: 0px;
    top: 0px;
    transition: all 200ms ease 0s;
  }

  .tabCommon.tabThree .nav-tabs li a.active {
    cursor: default;
    background-color: #ffffff;
    border-bottom-color: #ffffff;
  }

  .tabCommon.tabThree .nav-tabs li a.active:before {
    transform: scale(1);
  }

  .tabCommon.tabThree .nav-tabs > li {
    margin-bottom: -1px;
  }

  .tabCommon.singleTab .nav-tabs {
    border-bottom: none;
    margin-bottom: 20px;
  }

  .tabCommon.singleTab .nav-tabs li a {
    display: block;
    background-color: #ffffff;
    margin-left: 10px;
  }

  .tabCommon.singleTab .nav-tabs li a:hover {
    background-color: #ec5598;
    border: 1px solid #ec5598;
    color: #ffffff;
  }

  .tabCommon.singleTab .nav-tabs li a.active {
    background-color: #ec5598;
    border: 1px solid #ec5598;
    color: #ffffff;
  }

  .tabCommon.singleTab .nav-tabs li.active a {
    background-color: #ec5598;
    border: 1px solid #ec5598;
    color: #ffffff;
  }

  .tabCommon.singleTab .tab-content {
    border: none;
    padding: 40px;
  }

  .tabCommon.singleTab .tab-pane h4 {
    font-size: 24px;
    color: #ec5598;
    margin: 0 0 20px 0;
    font-weight: 400;
  }

  .tabCommon.singleTab .tab-pane p {
    line-height: 26px;
    margin: 0 0 25px 0;
  }

  .tabCommon.singleTab .tab-pane .list-inline {
    overflow: hidden;
  }

  @media (min-width: 320px) {
    .tabCommon.singleTab .tab-pane .list-inline {
      width: 100%;
    }
  }

  @media (min-width: 992px) {
    .tabCommon.singleTab .tab-pane .list-inline {
      width: 50%;
    }
  }

  .tabCommon.singleTab .tab-pane .list-inline li {
    float: right;
  }

  @media (min-width: 320px) {
    .tabCommon.singleTab .tab-pane .list-inline li {
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    .tabCommon.singleTab .tab-pane .list-inline li {
      width: 50%;
    }
  }

  .tabCommon.singleTab .tab-pane .list-inline li i.fa {
    margin-left: 10px;
  }

  .tabCommon.singleTab .tab-pane .blogCommnets {
    margin: 0;
  }

  .tabCommon.singleTab .tab-pane .blogCommnets h3 {
    font-size: 24px;
    margin-bottom: 35px;
    letter-spacing: 1px;
  }

  .tabCommon.singleTab .tab-pane .blogCommnets .media {
    padding-bottom: 15px;
    border-bottom: 1px dashed #b7b7b7;
  }

  @media (min-width: 320px) {
    .tabCommon.singleTab .tab-pane .blogCommnets .media {
      margin-top: 15px;
    }
  }

  @media (min-width: 768px) {
    .tabCommon.singleTab .tab-pane .blogCommnets .media {
      margin-top: 30px;
    }
  }

  @media (min-width: 320px) {
    .tabCommon.singleTab .tab-pane .blogCommnets .media > .pull-left {
      padding: 0 0 20px 0;
    }
  }

  @media (min-width: 768px) {
    .tabCommon.singleTab .tab-pane .blogCommnets .media > .pull-left {
      padding: 0 0 0 20px;
    }
  }

  .tabCommon.singleTab .tab-pane .blogCommnets .media-body h4 {
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 0 15px 0;
    font-size: 18px;
    color: #222222;
  }

  .tabCommon.singleTab .tab-pane .blogCommnets .media-body h5 {
    color: #666666;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .tabCommon.singleTab .tab-pane .blogCommnets .media-body h5 i.fa {
    color: #cdcdcd;
    margin-left: 7px;
  }

  .tabCommon.singleTab .tab-pane .blogCommnets .media-body .btn-link {
    color: #ec5598;
    text-transform: capitalize;
  }

  @media (min-width: 768px) {
    .tabCommon.singleTab .tab-pane .blogCommnets .mediaMargin {
      margin-right: 50px;
    }
  }

  @media (min-width: 768px) {
    .tabCommon.singleTab .tab-pane .blogCommnets .mediaMargin {
      margin-right: 100px;
    }
  }

  /*=== 2.9 MODAL ===*/
  @media (min-width: 992px) {
    .modalCommon .modal-dialog {
      max-width: 50%;
    }
  }

  @media (min-width: 1200px) {
    .modalCommon .modal-dialog {
      max-width: 45%;
    }
  }

  .modalCommon .modal-header {
    position: relative;
    border: none;
    padding: 10px 15px;
    background-color: #ec5598;
  }

  @media (min-width: 768px) {
    .modalCommon .modal-header {
      padding: 14px 15px;
    }
  }

  .modalCommon .modal-title {
    letter-spacing: 1px;
    font-size: 16px;
    color: #ffffff;
    text-transform: uppercase;
  }

  @media (min-width: 768px) {
    .modalCommon .modal-title {
      font-size: 20px;
    }
  }

  .modalCommon .modal-content {
    padding: 2px 3px;
    border-radius: 0;
    margin: 0 auto;
  }

  .modalCommon .modal-content .form-right {
    float: right;
  }

  @media (min-width: 768px) {
    .modalCommon .modal-content .form-right {
      float: left;
    }
  }

  .modalCommon button.close {
    margin: 0;
    width: 38px;
    height: 38px;
    border-radius: 100%;
    background-color: #292929;
    text-shadow: none;
    color: #ffffff;
    opacity: 1 !important;
    border: 2px solid #ffffff;
    position: absolute;
    padding: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.45);
  }

  @media (min-width: 320px) {
    .modalCommon button.close {
      top: -10px;
      left: -7px;
    }
  }

  @media (min-width: 768px) {
    .modalCommon button.close {
      top: -20px;
      left: -20px;
    }
  }

  .modalCommon .modal-footer {
    border: none;
    text-align: right;
  }

  .modalCommon .categoryTitle h5 {
    font-size: 20px;
    color: #222222;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
    letter-spacing: 1px;
  }

  .modalCommon .modal-body {
    padding: 50px 15px 15px 15px;
  }

  /*=== 2.9 MEDIA ===*/
  .media .media-left {
    width: 100%;
    display: block;
    padding-left: 0;
    position: relative;
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    .media .media-left {
      padding-left: 15px;
      display: table-cell;
      vertical-align: top;
    }
  }

  @media (min-width: 992px) {
    .media .media-left {
      padding-left: 25px;
    }
  }

  .media .media-left img {
    display: inline-block;
    max-width: 100%;
    position: relative;
    width: 100%;
  }

  .media .media-body {
    display: block;
    width: 100%;
  }

  @media (min-width: 768px) {
    .media .media-body {
      width: 10000px;
      display: table-cell;
      vertical-align: top;
    }
  }

  .media .media-heading {
    margin-bottom: 15px;
  }

  @media (min-width: 992px) {
    .media .media-heading {
      margin-bottom: 25px;
    }
  }

  .media .list-inline {
    margin-bottom: 20px;
  }

  .media .list-inline li {
    font-size: 13px;
  }

  .media .list-inline li i {
    margin-left: 8px;
  }

  .media .list-inline li a {
    color: #666666;
  }

  .media p {
    margin-bottom: 0;
  }

  .listView {
    margin-top: 25px;
    padding-bottom: 25px;
  }

  .listView:last-child {
    padding-bottom: 0;
  }

  @media (min-width: 768px) {
    .listView .media-left {
      width: 30%;
    }
  }

  @media (min-width: 992px) {
    .listView .media-left {
      width: 32%;
    }
  }

  @media (min-width: 992px) {
    .listView .media-heading {
      margin-bottom: 20px;
    }
  }

  .common-media {
    margin-top: 50px;
  }

  @media (min-width: 992px) {
    .common-media {
      margin-top: 100px;
    }
  }

  @media (min-width: 768px) {
    .common-media .media-left {
      width: 45%;
    }
  }

  @media (min-width: 992px) {
    .common-media .media-left {
      width: 35%;
    }
  }

  .sticker {
    width: 80px;
    height: 35px;
    text-align: center;
    display: inline-block;
    height: 35px;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    background-color: #ec5598;
    position: absolute;
    top: 0;
    right: 0;
  }

  @media (min-width: 480px) {
    .sticker {
      width: 100px;
      height: 45px;
      line-height: 45px;
      font-size: 22px;
    }
  }

  @media (min-width: 768px) {
    .sticker {
      width: 80px;
      height: 35px;
      height: 35px;
      font-size: 18px;
    }
  }

  @media (min-width: 992px) {
    .sticker {
      width: 100px;
      height: 45px;
      line-height: 45px;
      font-size: 22px;
    }
  }

  /*=== 2.13 PAGER ===*/
  .pagerArea > .pager {
    margin: 80px 0 250px 0;
  }

  .pagerArea > .pager li {
    padding: 0 15px;
  }

  .pagerArea > .pager li a {
    width: 114px;
    height: 45px;
    padding: 0 20px;
    border: none;
    line-height: 45px;
    box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #ec5598;
    border-radius: 6px;
    font-size: 16px;
    text-transform: uppercase;
    color: #ffffff;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
  }

  .pagerArea > .pager li:first-child a i {
    margin-left: 10px;
  }

  .pagerArea > .pager li:last-child a i {
    margin-right: 10px;
  }

  /*=== 2.14 ALERT ===*/
  .alertPart {
    padding: 0 0 70px 0;
  }

  .alert-common {
    color: #ffffff;
    font-size: 14px;
    padding: 11px 15px;
    margin: 0 auto 30px auto;
    width: 95%;
    border: none;
    border-radius: 0;
  }

  .alert-common span {
    font-weight: 600;
  }

  .alert-common.alert-success {
    background-color: #6fc96f;
  }

  .alert-common.alert-info {
    background-color: #4da5c9;
  }

  .alert-common.alert-warning {
    background-color: #e6b048;
  }

  .alert-common.alert-danger {
    background-color: #e26857;
  }

  .finalCart input[type=number]::-webkit-inner-spin-button {
    cursor: pointer;
  }

  .imagediv .showonhover {
    background: #ec5598;
    padding: 9px 0;
    font-size: 16px;
    letter-spacing: 1px;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;
    display: block;
    text-align: center;
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
  }

  @media (min-width: 768px) {
    .imagediv .showonhover {
      font-size: 12px;
      padding: 10px 0;
    }
  }

  @media (min-width: 992px) {
    .imagediv .showonhover {
      font-size: 14px;
      padding: 12px 0;
    }
  }

  /*================= 5. HOME ==================*/
  /*=== 3. HEADER ===*/
  /*=== 3.2 TOP INFO BAR ===*/
  .top-info-bar {
    display: block;
    width: 100%;
    z-index: 100;
    background-color: #ec5598;
    height: 45px;
  }

  @media (min-width: 320px) {
    .top-bar-right {
      text-align: right;
    }
  }

  @media (min-width: 768px) {
    .top-bar-right {
      text-align: left;
    }
  }

  .top-bar-right a, .top-bar-right span {
    color: rgba(255, 255, 255, 0.8);
    line-height: 45px;
    font-weight: 600;
  }

  .top-bar-right a i, .top-bar-right span i {
    margin-left: 10px;
  }

  @media (min-width: 320px) {
    .top-bar-right a, .top-bar-right span {
      font-size: 12px;
    }
  }

  @media (min-width: 768px) {
    .top-bar-right a, .top-bar-right span {
      font-size: 13px;
    }
  }

  /*=== 3. NAVBAR ===*/
  .main-nav {
    z-index: 999;
    padding: 0px;
    background-color: #ffffff;
    transition: all 0.3s ease-in-out;
  }

  @media (min-width: 768px) {
    .main-nav.navbar-sticky .nav-item a.nav-link {
      padding: 29px 10px;
    }
  }

  @media (min-width: 992px) {
    .main-nav.navbar-sticky .nav-item a.nav-link {
      padding: 29px 30px;
    }
  }


  @media (min-width: 768px) {
    .main-nav {
      position: relative;
      padding: 0 !important;
    }
  }

  .main-nav .navbar-brand {
    padding-top: 11px;
    margin: 0px;
    margin-right: 15px;
    padding-bottom: 11px;
    position: relative;
  }

  .main-nav .navbar-brand img {
    width: 75px;
    max-width: 100%;
  }

  @media (min-width: 768px) {
    .main-nav .navbar-brand img {
      width: auto;
      max-width: 100px;
    }
  }

  @media (min-width: 992px) {
    .main-nav .navbar-brand {
      width: 180px;
      overflow: visible;
    }
    .main-nav .navbar-brand img {
      width: auto;
      max-width: 100%;
    }
  }

  .main-nav .navbar-toggler {
    align-self: flex-end;
    padding: 0;
    background-color: #ec5598;
    border: none;
    cursor: pointer;
    border-radius: 0px;
    transition: all 0.5s ease-in-out;
    z-index: 5550;
    padding: 12px 11px;
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .main-nav .navbar-toggler .burger-menu {
    display: inline-block;
    font-size: 24px;
    background: transparent;
    text-align: center;
  }

  .main-nav .navbar-toggler.active, .main-nav .navbar-toggler:focus {
    outline: none;
  }

  .main-nav .navbar-collapse {
    padding: 0;
    border-top: none;
    max-height: 450px;
    position: absolute;
    width: 100%;
    top: 100%;
    right: 0;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 768px) {
    .main-nav .navbar-collapse {
      position: static;
      margin-top: 0px;
      box-shadow: none;
    }
  }

  .main-nav .dropdown-toggle::after {
    visibility: visible;
    position: absolute;
    left: 0px;
    margin-right: 0px;
    top: 50%;
    content: '\f107';
    font-family: 'FontAwesome';
    border: none;
    font-weight: normal;
    transform: translateY(-50%);
  }

  @media (min-width: 768px) {
    .main-nav .dropdown-toggle::after {
      visibility: hidden;
      display: none;
      margin: 0;
    }
  }

  .main-nav .navbar-collapse.show {
    overflow-y: auto;
    left: 0;
  }

  .main-nav .navbar-nav {
    position: relative;
    z-index: 5551;
    background: #ffffff;
    padding: 5px 0 5px;
  }

  @media (min-width: 768px) {
    .main-nav .navbar-nav {
      padding: 0;
      position: static;
      background: transparent;
    }
  }

  .main-nav .nav-item a {
    text-transform: capitalize;
    font-style: normal;
  }

  .main-nav .nav-item a.nav-link {
    color: #222222;
    padding: 0px;
    font-size: 13px;
    position: relative;
    text-transform: uppercase;
    font-weight: 700;
    display: block;
    margin: 10px 15px;
    transition: all 0.3s ease;
  }

  @media (min-width: 768px) {
    .main-nav .nav-item a.nav-link {
      padding: 35px 10px;
      margin: 0;
      color: #222222;
      font-size: 13px;
    }
  }

  @media (min-width: 992px) {
    .main-nav .nav-item a.nav-link {
      padding: 35px 15px;
    }
  }

  @media (min-width: 1200px) {
    .main-nav .nav-item a.nav-link {
      padding: 35px 20px;
    }
  }

  @media (min-width: 768px) {
    .main-nav .nav-item a.nav-link:hover {
      color: #ec5598;
    }
  }

  @media (min-width: 768px) {
    .main-nav .nav-item a.nav-link:hover:before, .main-nav .nav-item a.nav-link:hover:after {
      opacity: 1;
    }
  }

  .main-nav .nav-item a.nav-link.btn-search:hover, .main-nav .nav-item a.nav-link.btn-search:focus {
    color: #222222;
  }

  .main-nav .nav-item.active a.nav-link:before {
    opacity: 1;
  }

  .main-nav .nav-item.active a.nav-link:after {
    opacity: 1;
  }

  .main-nav .nav-item.active a.nav-link {
    color: #ec5598;
  }

  .main-nav .nav-item.show a.nav-link {
    color: #ec5598;
  }

  .main-nav .nav-item.show > .dropdown-toggle::after {
    content: '\f106';
  }

  .main-nav .show > .dropdown-menu {
    visibility: visible;
    transform: translateY(0%);
    opacity: 1;
    z-index: 1;
    transition: all 0.3s ease-in-out;
  }

  .main-nav .dropdown-menu {
    top: 80%;
    right: 7px;
    border: 0px;
    min-width: 230px;
    margin: 0 15px;
    width: auto;
    padding: 0 20px;
    border-radius: 0px;
    box-shadow: none;
  }

  .main-nav .dropdown-menu:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    left: 0;
    height: 3px;
    background: #ec5598;
    visibility: hidden;
    opacity: 0;
  }

  @media (min-width: 768px) {
    .main-nav .dropdown-menu:before {
      visibility: visible;
      opacity: 1;
    }
  }

  .main-nav .dropdown-menu a {
    padding: 5px 0;
    font-size: 12px;
    display: block;
    color: #666666;
    transition: color 0.2s, background-color 0.2s ease-in-out;
  }

  @media (min-width: 768px) {
    .main-nav .dropdown-menu a {
      padding: 8px 25px;
      font-size: 12px;
      color: #545454;
    }
    .main-nav .dropdown-menu a:hover {
      color: #ec5598;
    }
  }

  @media (min-width: 992px) {
    .main-nav .dropdown-menu a {
      padding: 10px 20px;
    }
  }

  @media (min-width: 768px) {
    .main-nav .dropdown-menu {
      box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.1);
      transform: translateY(0px);
      background-color: #ffffff;
      margin: 0px;
      padding: 0px;
      display: block;
      min-width: 200px;
      opacity: 0;
      right: 0px;
      left: 0;
      z-index: -1;
      visibility: hidden;
      top: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  @media (min-width: 992px) {
    .main-nav .dropdown-menu {
      margin-top: 0px;
      min-width: 200px;
      padding: 0px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  @media (min-width: 1200px) {
    .main-nav .dropdown-menu {
      min-width: 230px;
    }
  }

  .main-nav .dropdown-menu li {
    padding: 0;
  }

  .main-nav .dropdown-menu li.dropdown > a:after {
    position: absolute;
    content: '\f107';
    font-family: 'FontAwesome';
    top: 15px;
    left: -20px;
    font-size: 15px;
    visibility: visible;
    display: block;
  }

  @media (min-width: 768px) {
    .main-nav .dropdown-menu li.dropdown > a:after {
      content: '\f105';
      top: 19px;
      left: 15px;
    }
  }

  .main-nav .dropdown-menu li.dropdown .submenu {
    border: none;
    margin-top: 0px;
    margin-right: 0px;
    border: 0px;
    background: transparent;
    transform: translateY(0);
    margin: 0;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .main-nav .dropdown-menu li.dropdown .submenu:before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 3px;
    background: #ec5598;
    opacity: 0;
    visibility: hidden;
  }

  @media (min-width: 768px) {
    .main-nav .dropdown-menu li.dropdown .submenu {
      display: none;
      top: 0 !important;
      right: -100% !important;
      margin-top: 0px;
      background: #ffffff;
      padding-top: 10px;
      padding-bottom: 10px;
      left: auto;
    }
    .main-nav .dropdown-menu li.dropdown .submenu:before {
      right: auto;
      left: 0;
    }
  }

  @media (min-width: 1200px) {
    .main-nav .dropdown-menu li.dropdown .submenu {
      right: 100% !important;
    }
  }

  .main-nav .dropdown-menu li.dropdown.show {
    background-color: transparent;
  }

  .main-nav .dropdown-menu li.dropdown.show > a {
    color: #ec5598;
  }

  .main-nav .dropdown-menu li.dropdown.show > .dropdown-menu {
    display: block;
  }

  .main-nav .dropdown-menu li.dropdown.show > a:after {
    content: '\f106';
  }

  @media (min-width: 768px) {
    .main-nav .dropdown-menu li.dropdown.show > a:after {
      content: '\f105';
    }
  }

  .menu-right {
    right: -100% !important;
  }

  @media (min-width: 992px) {
    .menu-right {
      right: -50% !important;
    }
  }

  @media (min-width: 1200px) {
    .menu-xl-right {
      right: -100% !important;
    }
  }

  .navbar-light {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 768px) {
    .megaDropMenu {
      position: static;
    }
    .megaDropMenu .dropdown-menu {
      left: 0;
      right: 0px;
      margin: 0 15px;
      padding: 0 0px;
    }
  }

  @media (min-width: 768px) and (min-width: 768px) {
    .megaDropMenu .dropdown-menu {
      padding: 18px;
    }
  }

  @media (min-width: 768px) {
    .megaDropMenu .dropdown-menu > li {
      float: right;
      padding: 0 0px;
      border-bottom: 0px;
    }
  }

  @media (min-width: 768px) and (min-width: 768px) {
    .megaDropMenu .dropdown-menu > li:nth-child(2) {
      border-right: 1px solid #dedede;
      border-left: 1px solid #dedede;
    }
  }

  @media (min-width: 768px) {
    .megaDropMenu .dropdown-menu ul li:last-child {
      border-bottom: none;
    }
    .megaDropMenu .dropdown-menu ul li a {
      padding: 8px 20px;
    }
  }

  @media (min-width: 768px) and (min-width: 992px) {
    .megaDropMenu .dropdown-menu ul li a {
      padding: 10px 25px;
    }
  }

  @media (min-width: 768px) {
    .megaDropMenu .dropdown-menu.justify-content-md-between > li {
      display: flex;
      flex-direction: column;
      width: 25%;
    }
  }

  .navbar-brand .path-2 {
    fill: #222222;
  }

  .navbar-sticky {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    z-index: 1030;
    transition: all 0.3s ease-in-out;
  }

  .static .navbar-sticky {
    display: none;
  }

  .main-nav .nav-item .btn-search {
    top: 0;
    left: 0;
  }

  .main-nav .nav-item .btn-search.nav-link {
    display: none;
    margin-left: 0px !important;
  }

  @media (min-width: 768px) {
    .main-nav .nav-item .btn-search.nav-link {
      display: block;
    }
  }

  .search_hook {
    position: relative;
  }

  .search_hook:before {
    position: absolute;
    content: '';
    right: 0px;
    width: 2px;
    height: 14px;
    background: #ccc;
    top: 50%;
    transform: translateY(-50%);
    display: none;
  }

  @media (min-width: 768px) {
    .search_hook:before {
      display: block;
    }
  }

  .search_hook form.search_form {
    margin-top: 5px;
    position: relative;
    left: 0;
    background: #f5f5f6;
    top: 80%;
    z-index: 10;
    padding: 20px 30px;
    border-top: 1px solid #cccccc;
    box-shadow: -2px 3px 9px rgba(44, 40, 86, 0.1);
  }

  @media (min-width: 768px) {
    .search_hook form.search_form {
      border-top: 3px solid #ec5598;
      width: 300px;
      display: none;
      background: #ffffff;
      position: absolute;
      margin: 0px;
      padding: 15px;
    }
  }

  @media (min-width: 992px) {
    .search_hook form.search_form {
      padding: 20px;
    }
  }

  .search_hook form.search_form input {
    display: inline-block;
    padding: 10px 15px;
    font-size: 14px;
    max-width: 100%;
    width: 100%;
    background: #ffffff;
    color: #666666;
    border: 1px solid #e6e6e6;
  }

  @media (min-width: 768px) {
    .search_hook form.search_form input {
      background: #f5f5f6;
    }
  }

  .search_hook form.search_form .btn-small {
    font-size: 12px;
    float: left;
  }

  .search_hook form.search_form .btn-search {
    top: 50%;
    transform: translateY(-50%);
    left: 45px;
    color: #222222;
  }

  @media (min-width: 768px) {
    .search_hook form.search_form .btn-search {
      left: 30px;
    }
  }

  .icon-toggle {
    display: table-cell;
    transition: all 0.3s ease 0s;
    vertical-align: middle;
  }

  .icon-toggle.active > i {
    opacity: 0;
    transform: translateY(-20px);
  }

  .icon-toggle.active::after {
    opacity: 1;
    transform: translateY(0px);
  }

  .icon-toggle > i {
    display: inline-block;
    font-style: normal;
    opacity: 1;
    position: relative;
    transform: translateY(0px);
    transition: transform 0.3s ease 0s, opacity 0.15s ease 0s;
  }

  .icon-toggle::after {
    content: "\e082";
    display: block;
    font-family: 'simple-line-icons';
    margin-top: -23px;
    opacity: 0;
    transform: translateY(20px) scale(1);
    transition: transform 0.3s ease 0s, opacity 0.15s ease 0s;
  }

  .header-transparent {
    position: absolute;
    z-index: 55;
    padding: 0px;
    width: 100%;
  }

  @media (min-width: 992px) {
    .header-transparent .navbar-brand img {
      max-width: 100%;
    }
  }

  .header-transparent .navbar-brand .path-2 {
    fill: #ffffff;
  }

  .header-transparent .navbar-nav.mlr-auto {
    margin: 0 auto;
  }

  .header-transparent .nav-item a.nav-link {
    color: #222222;
  }

  @media (min-width: 768px) {
    .header-transparent .nav-item a.nav-link {
      color: #ffffff;
    }
  }

  .header-transparent .nav-item a.nav-link.btn-search:hover, .header-transparent .nav-item a.nav-link.btn-search:focus {
    color: #ffffff;
  }

  .header-transparent.navbar-sticky {
    position: fixed;
    // background-image: none;
    background-color: #ffffff;
  }

  .header-transparent.navbar-sticky .navbar-brand .path-2 {
    fill: #222222;
  }

  .header-transparent.navbar-sticky .nav-item a.nav-link {
    color: #222222;
  }

  .header-transparent.navbar-sticky .nav-item a.nav-link:hover {
    color: #ec5598;
  }

  .header-transparent.navbar-sticky.main-nav .nav-item.active a.nav-link {
    color: #ec5598;
  }

  .header-transparent.navbar-sticky.main-nav .nav-item a.btn-search:focus {
    color: #222222;
  }

  @media (min-width: 768px) {
    .bg-dark a.nav-link {
      color: #ffffff !important;
    }
  }

  .display-single_element .bg-dark .main-nav .nav-item a.nav-link {
    color: #ffffff;
  }

  .display-single_element .bg-dark .main-nav a.nav-link:before {
    border-color: #ffffff;
  }

  .display-single_element .main-nav a.nav-link:before {
    border-color: #222222;
  }

  .display-single_element .main-nav a.nav-link:after {
    border-color: transparent;
  }

  .display-single_element .main-nav {
    box-shadow: none;
  }

  .display-single_element .main-nav .nav-item a.nav-link {
    color: #222222;
  }

  .display-single_element .main-nav .navbar-collapse {
    margin-top: 20px;
    height: 200px;
  }

  @media (min-width: 768px) {
    .display-single_element .main-nav .navbar-collapse {
      margin-top: 0px;
      height: auto;
    }
  }

  .display-single_element .main-nav .navbar-collapse .navbar-nav {
    background-color: #ffffff;
  }

  @media (min-width: 768px) {
    .display-single_element .main-nav .navbar-collapse .navbar-nav {
      background-color: transparent;
    }
  }

  .display-single_element .main-nav .dropdown-menu a {
    color: #222222;
  }

  .display-single_element .main-nav .navbar-toggler, .display-single_element .main-nav .nav-cart {
    top: 20px;
  }

  @media (min-width: 768px) {
    .display-single_element .main-nav .navbar-toggler, .display-single_element .main-nav .nav-cart {
      top: 0;
    }
  }

  .display-single_element .main-nav .navbar-brand {
    margin-right: -15px;
  }

  @media (min-width: 768px) {
    .display-single_element .main-nav .navbar-brand {
      margin-right: unset;
    }
  }

  .logo-center .main-nav .nav-item a.nav-link {
    color: #222222;
  }

  @media (min-width: 768px) {
    .logo-center {
      // background-image: url("../img/slider/slv1.jpg");
      background-position: bottom;
    }
    .logo-center .navbar-brand {
      position: absolute;
      width: 100%;
      right: 0;
      text-align: center;
      margin: auto;
      margin-right: -30px;
    }
  }

  @media (min-width: 768px) and (min-width: 768px) {
    .logo-center .navbar-brand {
      width: 150px;
      right: 50%;
      transform: translateX(50%);
    }
  }

  .up-scroll .main-nav {
    width: 100%;
  }

  .up-scroll .navbar-scrollUp.is-visible {
    transform: translate3d(0, 100%, 0);
    background: transparent;
  }

  .up-scroll .navbar-scrollUp.is-visible .nav-item.active a.nav-link {
    color: #ec5598;
  }

  .up-scroll .navbar-scrollUp.is-visible .path-2 {
    fill: #222222;
  }

  .up-scroll .navbar-scrollUp.is-fixed {
    position: fixed;
    transition: transform .2s;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    top: -55px;
  }

  @media (min-width: 768px) {
    .up-scroll .navbar-scrollUp.is-fixed {
      top: -82px;
    }
  }

  @media (min-width: 992px) {
    .up-scroll .navbar-scrollUp.is-fixed {
      top: -102px;
    }
  }

  .dropdown-menu a.active,
  .dropdown-item.active {
    color: #ec5598;
    background-color: transparent;
  }

  @media (min-width: 768px) {
    .dropdown-menu a.active:hover,
    .dropdown-item.active:hover {
      background-color: #ec5598;
      color: #ffffff !important;
    }
  }

  .navbar-toggler .icon-bar {
    width: 22px;
    height: 2px;
    background-color: #ffffff;
    display: block;
    margin-top: 4px;
  }

  .navbar-toggler .icon-bar:nth-of-type(1) {
    margin-top: 0;
  }

  .cart_btn {
    position: relative;
    left: 20px;
  }

  @media (min-width: 768px) {
    .cart_btn {
      left: 0;
    }
  }

  .cart_btn a {
    color: #222222;
    display: inline-block;
    position: relative;
  }

  .cart_btn a i {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #eaeaea;
  }

  .cart_btn a span {
    color: #ffffff;
    display: inline-block;
    padding: 0;
    width: 19px;
    height: 19px;
    line-height: 19px;
    background-color: #ec5598;
    position: absolute;
    top: -7px;
    left: -7px;
    border-radius: 50%;
  }

  /*=== 1.MAIN SLIDER ===*/
  .main-slider {
    width: 100%;
    overflow: hidden;
  }

  .main-slider .slide {
    display: block;
    width: 100%;
    height: 300px;
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media (min-width: 320px) {
    .main-slider .slide {
      height: 510px;
    }
  }

  @media (min-width: 992px) {
    .main-slider .slide {
      height: 800px;
    }
  }

  @media (min-width: 320px) {
    .main-slider .slide.slide1 {
      background-position: right center;
    }
  }

  @media (min-width: 992px) {
    .main-slider .slide.slide1{
      background-position: right center;
    }
  }
  @media (min-width: 0px) {
    .main-slider .slide.slide1 {
      background-position: center center;
    }
  }
  .main-slider .owl-carousel .owl-controls .owl-nav {
    display: none;
    height: 0;
  }

  @media (min-width: 768px) {
    .main-slider .owl-carousel .owl-controls .owl-nav {
      display: block;
    }
  }

  .main-slider .slide > .container {
    position: relative;
    display: table;
    height: 100%;
  }

  .main-slider .slide h1,
  .main-slider .slide h2,
  .main-slider .slide h3,
  .main-slider .slide h4,
  .main-slider .slide h5,
  .main-slider .slide h6,
  .main-slider .slide p,
  .main-slider .slide .h1,
  .main-slider .slide .h2,
  .main-slider .slide .h3,
  .main-slider .slide .h4,
  .main-slider .slide .h5,
  .main-slider .slide .h6 {
    display: block;
    margin-bottom: 0;
  }

  .main-slider .owl-carousel .owl-controls .owl-nav .owl-prev,
  .main-slider .owl-carousel .owl-controls .owl-nav .owl-next {
    width: 40px;
    height: 90px;
    line-height: 90px;
    font-size: 40px;
    font-weight: 700;
    transition: all 0.4s;
  }

  .main-slider .owl-carousel .owl-controls .owl-nav .owl-prev,
  .main-slider .owl-carousel .owl-controls .owl-nav .owl-next,
  .main-slider .owl-carousel .owl-controls .owl-dot {
    padding: 0;
    background-color: transparent;
    color: #ec5598;
    border-radius: 0;
    box-shadow: none;
  }

  .main-slider .owl-carousel .owl-controls .owl-nav .owl-prev:before {
    line-height: 90px;
    content: "\f104";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 90px;
  }

  .main-slider .owl-carousel .owl-controls .owl-nav .owl-next:before {
    content: "\f105";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 90px;
  }

  .main-slider .owl-carousel .owl-controls .owl-nav .owl-prev {
    right: -120px;
  }

  .main-slider .owl-carousel .owl-controls .owl-nav .owl-next {
    left: -120px;
  }

  .main-slider:hover .owl-carousel .owl-controls .owl-nav .owl-prev {
    right: 60px;
    margin-right: 30px;
  }

  .main-slider:hover .owl-carousel .owl-controls .owl-nav .owl-next {
    left: 60px;
    margin-left: 30px;
  }

  .main-slider .slider-layer {
    position: absolute;
  }

  .main-slider .owl-item .from-top,
  .main-slider .owl-item .from-bottom,
  .main-slider .owl-item .from-left,
  .main-slider .owl-item .from-right,
  .main-slider .owl-item .scale-up,
  .main-slider .owl-item .scale-down {
    opacity: 0;
    transition: all 0.5s 0.3s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .main-slider .owl-item .from-top {
    transform: translateY(-45px);
  }

  .main-slider .owl-item .from-bottom {
    transform: translateY(45px);
  }

  .main-slider .owl-item .from-left {
    transform: translateX(45px);
  }

  .main-slider .owl-item .from-right {
    transform: translateX(-45px);
  }

  .main-slider .owl-item .scale-up {
    transform: scale(0.8);
  }

  .main-slider .owl-item .scale-down {
    transform: scale(1.2);
  }

  .main-slider .owl-item .delay-1 {
    transition-delay: 0.5s;
  }

  .main-slider .owl-item .delay-2 {
    transition-delay: 0.7s;
  }

  .main-slider .owl-item .delay-3 {
    transition-delay: 0.9s;
  }

  .main-slider .owl-item .delay-4 {
    transition-delay: 1.1s;
  }

  .main-slider .owl-item.active .from-top,
  .main-slider .owl-item.active .from-bottom {
    opacity: 1;
    transform: translateY(0);
  }

  .main-slider .owl-item.active .from-left,
  .main-slider .owl-item.active .from-right {
    opacity: 1;
    transform: translateX(0);
  }

  .main-slider .owl-item.active .scale-up,
  .main-slider .owl-item.active .scale-down {
    opacity: 1;
    transform: scale(1);
  }

  .main-slider .owl-controls {
    margin: 0;
  }

  .main-slider .owl-controls .owl-dots {
    display: none !important;
  }

  @media (min-width: 768px) {
    .main-slider .common-inner {
      padding-right: 0;
    }
  }

  @media (min-width: 768px) {
    .main-slider .common-inner {
      padding-right: 15px;
    }
  }

  .owl-stage-outer {
    border-bottom: 4px solid #ec5598;
  }

  .main-slider .slide-inner1 {
    position: absolute;
  }

  @media (min-width: 320px) {
    .main-slider .slide-inner1 {
      transform: translate(0, -50%);
      top: 50%;
      right: 10%;
    }
  }

  @media (min-width: 768px) {
    .main-slider .slide-inner1 {
      transform: translate(0, -30%);
      top: 50%;
      right: 0;
    }
  }

  .main-slider .common-inner .h1 {
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
  }

  @media (min-width: 320px) {
    .main-slider .common-inner .h1 {
      font-size: 20px;
      line-height: 20px;
      margin: 0;
    }
  }

  @media (min-width: 768px) {
    .main-slider .common-inner .h1 {
      font-size: 50px;
      line-height: 50px;
      margin: 0 0 10px;
    }
  }

  @media (min-width: 992px) {
    .main-slider .common-inner .h1 {
      font-size: 55px;
      line-height: 55px;
      margin: 0 0 10px;
    }
  }

  .main-slider .common-inner .h4 {
    color: white;
    margin-bottom: 10px;
    margin-top: 0;
    text-transform: capitalize;
  }

  @media (min-width: 320px) {
    .main-slider .common-inner .h4 {
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 0;
    }
  }

  @media (min-width: 768px) {
    .main-slider .common-inner .h4 {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 10px;
    }
  }

  .main-slider .common-inner a {
    text-align: center;
    display: block;
    padding: 17px 0;
  }

  @media (min-width: 320px) {
    .main-slider .common-inner a {
      width: 140px;
    }
  }

  @media (min-width: 768px) {
    .main-slider .common-inner a {
      width: 184px;
    }
  }

  @media (min-width: 320px) {
    .main-slider .common-inner .img-responsive {
      margin-bottom: 5px;
      width: 65px;
    }
  }

  @media (min-width: 768px) {
    .main-slider .common-inner .img-responsive {
      width: 95px;
      margin-bottom: 30px;
    }
  }

  .main-slider .slide-inner3 {
    position: absolute;
  }

  @media (min-width: 320px) {
    .main-slider .slide-inner3 {
      transform: translate(0, -50%);
      top: 50%;
      right: 10%;
    }
  }

  @media (min-width: 768px) {
    .main-slider .slide-inner3 {
      transform: translate(0, -50%);
      top: 50%;
      right: 50%;
    }
  }

  /*================= 5.1 ABOUT SECTION ==================*/
  .aboutSection {
    width: 100%;
    float: none;
  }

  @media (min-width: 320px) {
    .aboutSection {
      padding: 0 15px;
    }
  }

  @media (min-width: 768px) {
    .aboutSection {
      padding: 0 0 50px 0;
    }
  }

  .aboutInner {
    position: relative;
  }

  @media (min-width: 320px) {
    .aboutInner {
      height: auto;
      padding: 0 15px 0 15px;
      background-color: transparent;
    }
  }

  @media (min-width: 768px) {
    .aboutInner {
      width: 50%;
      float: left;
      height: 650px;
      padding: 120px 0 0 0;
      background-color: #ffffff;
    }
  }

  @media (min-width: 320px) {
    .sepcialContainer {
      width: 100%;
      position: relative;
      right: 0;
      transform: translate(0, 0);
    }
  }

  @media (min-width: 768px) {
    .sepcialContainer {
      width: 720px;
      position: absolute;
      right: 0;
      transform: translate(50%, 0);
    }
  }

  @media (min-width: 992px) {
    .sepcialContainer {
      width: 970px;
    }
  }

  @media (min-width: 1200px) {
    .sepcialContainer {
      width: 1170px;
    }
  }

  @media (min-width: 320px) {
    .rightPadding {
      padding: 50px 15px;
    }
  }

  @media (min-width: 768px) {
    .rightPadding {
      padding: 0 15px 0 0;
    }
  }

  @media (min-width: 320px) {
    .imagebox {
      border: 5px solid #ffffff;
    }
  }

  @media (min-width: 768px) {
    .imagebox {
      border: 5px solid #ffffff;
      border-left: 0;
    }
  }

  .imagebox img {
    width: 100%;
    margin: auto;
  }

  @media (min-width: 320px) {
    .imagebox img {
      margin: -10px 10px 10px 0;
    }
  }

  @media (min-width: 768px) {
    .imagebox img {
      margin: -40px 40px 40px 0;
    }
  }

  @media (min-width: 992px) {
    .imagebox img {
      margin: -60px 60px 60px 0;
    }
  }

  @media (min-width: 320px) {
    .aboutInfo {
      padding: 50px 30px;
      background-color: #ffffff;
      margin: 0 -30px;
    }
  }

  @media (min-width: 768px) {
    .aboutInfo {
      padding: 0 55px 0 0;
      background-color: transparent;
      margin: 0;
    }
  }

  @media (min-width: 992px) {
    .aboutInfo {
      padding: 0 95px 0 0;
    }
  }

  .aboutInfo h2 {
    letter-spacing: 10px;
    font-size: 20px;
    color: #222222;
  }

  @media (min-width: 320px) {
    .aboutInfo h2 {
      line-height: 35px;
    }
  }

  @media (min-width: 768px) {
    .aboutInfo h2 {
      line-height: 30px;
    }
  }

  @media (min-width: 992px) {
    .aboutInfo h2 {
      line-height: 40px;
    }
  }

  .aboutInfo h3 {
    font-size: 14px;
    color: #222222;
    text-transform: capitalize;
    line-height: 26px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
  }

  .aboutInfo p {
    font-size: 14px;
  }

  /*================= 5.2 VARIETY SECTION ==================*/
  .varietySection {
    padding: 0 0 100px 0;
    background-color: #ffffff;
    width: 100%;
    float: none;
  }

  .tabs-left > .nav-tabs {
    border-bottom: 0;
  }

  .tab-content > .tab-pane {
    display: none;
  }

  .tab-content > .active {
    display: block;
  }

  .tabs-left > .nav-tabs > li {
    float: none;
    display: block !important;
  }

  .tabs-left > .nav-tabs > li > a {
    min-width: 74px;
    margin-left: 0;
    margin-bottom: 3px;
  }

  .tabs-left > .nav-tabs {
    float: right;
    margin-left: 19px;
    border-left: 1px solid #ddd;
  }

  .tabs-left > .nav-tabs > li > a {
    margin-left: -1px;
    border-radius: 0 4px 4px 0;
  }

  .tabs-left > .nav-tabs > li > a:hover,
  .tabs-left > .nav-tabs > li > a:focus {
    border-color: #eeeeee #eeeeee #eeeeee #dddddd;
  }

  .tabs-left > .nav-tabs .active > a,
  .tabs-left > .nav-tabs .active > a:hover,
  .tabs-left > .nav-tabs .active > a:focus {
    border-color: #ddd #ddd #ddd transparent;
    border-left-color: #ffffff;
  }

  .tabTop .nav {
    justify-content: center;
    border: none;
  }

  .tabTop .nav li {
    display: inline-block;
    float: none;
  }

  .tabTop .nav li a {
    border: none;
    font-size: 14px;
    line-height: 26px;
    color: #222222;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    padding: 10px 15px;
    display: inline-block;
  }

  @media (min-width: 768px) {
    .tabTop .nav li a {
      padding: 0 23px;
    }
  }

  @media (min-width: 992px) {
    .tabTop .nav li a {
      padding: 0 33px;
    }
  }

  .tabTop .nav li a span {
    border-bottom: 2px solid transparent;
  }

  .tabTop .nav li a:hover, .tabTop .nav li a.active {
    background-color: transparent;
    color: #ec5598;
  }

  .tabTop .nav li a:hover span, .tabTop .nav li a.active span {
    border-bottom: 2px solid rgba(236, 85, 152, 0.5);
  }

  .tabTop .nav li a.active {
    cursor: default;
  }

  .tabTop .nav li.active a {
    border: none;
    color: #ec5598;
    background-color: transparent;
  }

  .tabTop .nav li.active a:hover {
    border: none;
    color: #ec5598;
    background-color: transparent;
  }

  .tabTop .nav li.active a span {
    border-bottom: 2px solid rgba(236, 85, 152, 0.5);
  }

  @media (min-width: 768px) {
    .tabTop .tab-content {
      padding: 40px 0 0 0;
    }
  }

  @media (min-width: 992px) {
    .tabTop .tab-content {
      padding: 40px 0 0 0;
    }
  }

  .tabs-left .tab-content {
    padding-top: 0;
  }

  .tabs-left .nav {
    box-shadow: 0 7px 21px rgba(0, 0, 0, 0.1);
    margin-left: 0;
    padding: 25px 0;
    flex-direction: column;
    margin-right: -15px !important;
    margin-left: -15px !important;
  }

  @media (min-width: 320px) {
    .tabs-left .nav {
      margin: 0 0 50px 0;
    }
  }

  @media (min-width: 768px) {
    .tabs-left .nav {
      margin: 0 !important;
    }
  }

  @media (min-width: 320px) {
    .tabs-left .nav li {
      padding: 0 30px;
    }
  }

  @media (min-width: 768px) {
    .tabs-left .nav li {
      padding: 0 20px;
    }
  }

  @media (min-width: 992px) {
    .tabs-left .nav li {
      padding: 0 35px;
    }
  }

  @media (min-width: 1200px) {
    .tabs-left .nav li {
      padding: 0 45px;
    }
  }

  .tabs-left .nav li a {
    padding: 20px 0;
    border-bottom: 1px dashed #b7b7b7;
    display: block;
  }

  .tabs-left .nav li a span {
    border-bottom: none !important;
    float: left;
  }

  .tabs-left .nav li a:hover, .tabs-left .nav li a.active {
    border-bottom: 1px dashed #ec5598;
  }

  .tabs-left .nav li a:hover span, .tabs-left .nav li a.active span {
    border-bottom: none;
  }

  .tabs-left .nav li.active a {
    border-bottom: 1px dashed #ec5598;
  }

  .tabs-left .nav li.active a:hover, .tabs-left .nav li.active a:focus, .tabs-left .nav li.active a a:active {
    border-bottom: 1px dashed #ec5598;
  }

  .tabs-left .nav li.active a span {
    border-bottom: none;
  }

  .tabs-left .nav li:last-child a {
    border: none;
  }

  .varietyContent img {
    margin-bottom: 25px;
  }

  .varietyContent h3 {
    font-size: 20px;
    line-height: 40px;
    margin: 0 0 15px 0;
    font-weight: 400;
  }

  .varietyContent h4 {
    font-size: 15px;
    line-height: 26px;
    color: #ec5598;
    margin: 0 0 25px 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
  }

  .varietyContent p {
    font-size: 14px;
    line-height: 26px;
    color: #666666;
    margin: 0 0 25px 0;
  }

  .varietyContent a {
    width: 239px;
  }

  /*================= 5.3 OFFEERS SECTION ==================*/
  .offersSection {
    padding: 80px 0;
    width: 100%;
    float: none;
  }

  .offerContent {
    position: relative;
    border-radius: 100%;
    border: 7px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0 7px 21px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 320px) {
    .offerContent {
      width: 254px;
      height: 254px;
      margin: 0 auto 30px auto;
    }
  }

  @media (min-width: 768px) {
    .offerContent {
      width: 160px;
      height: 160px;
      margin: 0 auto;
    }
  }

  @media (min-width: 992px) {
    .offerContent {
      width: 200px;
      height: 200px;
    }
  }

  @media (min-width: 1200px) {
    .offerContent {
      width: 254px;
      height: 254px;
    }
  }

  .offerContent img {
    border-radius: 100%;
    max-width: 100%;
    height: 100%;
  }

  .offerContent .offerMasking {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    border-radius: 100%;
  }

  .offerContent .offerTitle {
    width: 100%;
    background-color: rgba(236, 85, 152, 0.7);
    text-align: center;
    border-radius: 0 0 100% 100%;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .offerContent:hover .offerTitle {
    display: none;
  }

  @media (min-width: 320px) {
    .offerContent .offerTitle {
      padding: 12px 0 40px 0;
    }
  }

  @media (min-width: 768px) {
    .offerContent .offerTitle {
      padding: 5px 0 30px 0;
    }
  }

  @media (min-width: 992px) {
    .offerContent .offerTitle {
      padding: 12px 0 40px 0;
    }
  }

  .offerContent .offerTitle h4 {
    margin: 0;
  }

  .offerContent .offerTitle h4 a {
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 400;
  }

  @media (min-width: 768px) {
    .offerContent .offerTitle h4 a {
      line-height: 20px;
      font-size: 12px;
    }
  }

  @media (min-width: 992px) {
    .offerContent .offerTitle h4 a {
      line-height: 20px;
      font-size: 20px;
    }
  }

  .offerContent .offerPrice {
    width: 65px;
    height: 65px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 100%;
    position: absolute;
    box-shadow: 0 7px 21px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 320px) {
    .offerContent .offerPrice {
      left: -27px;
      bottom: -3px;
    }
  }

  @media (min-width: 768px) {
    .offerContent .offerPrice {
      left: -10px;
      bottom: -35px;
    }
  }

  @media (min-width: 992px) {
    .offerContent .offerPrice {
      left: -27px;
      bottom: -3px;
    }
  }

  .offerContent .offerPrice h5 {
    font-family: 'Open Sans', sans-serif;
    line-height: 65px;
    margin: 0;
    font-size: 20px;
    color: #ec5598;
  }

  /*================= 5.4 PRODUCT SECTION ==================*/
  #productSlider .carousel-indicators {
    display: none;
  }

  #productSlider .carousel-control {
    opacity: 1;
    width: auto;
    position: absolute;
    font-size: 20px;
  }

  @media (min-width: 320px) {
    #productSlider .carousel-control {
      top: -30px;
      bottom: auto;
    }
  }

  @media (min-width: 768px) {
    #productSlider .carousel-control {
      top: 0;
      bottom: 0;
    }
  }

  #productSlider .carousel-control.left {
    // background-image: none;
    color: #ec5598;
  }

  @media (min-width: 320px) {
    #productSlider .carousel-control.left {
      right: 50%;
      left: auto;
      transform: translate(140%, 0);
    }
  }

  @media (min-width: 768px) {
    #productSlider .carousel-control.left {
      right: auto;
      left: 30px;
      transform: translate(0, 0);
    }
  }

  #productSlider .carousel-control.left i.fa-long-arrow-left {
    text-shadow: none;
  }

  #productSlider .carousel-control.right {
    // background-image: none;
    color: #ec5598;
  }

  @media (min-width: 320px) {
    #productSlider .carousel-control.right {
      left: auto;
      right: 50%;
      top: -30px;
      transform: translate(0, 0);
    }
  }

  @media (min-width: 768px) {
    #productSlider .carousel-control.right {
      left: 0;
      top: 0;
      right: auto;
      transform: translate(0, 0);
    }
  }

  #productSlider .carousel-control.right i.fa-long-arrow-right {
    text-shadow: none;
  }

  .productInfo h3 {
    font-weight: 400;
    margin: 0 0 18px 0;
  }

  .productInfo h4 {
    font-family: 'Open Sans', sans-serif;
    line-height: 26px;
    margin: 0 0 14px 0;
    font-size: 16px;
    color: #ec5598;
    font-weight: 400;
  }

  .productInfo p {
    font-size: 14px;
    line-height: 26px;
    color: #666666;
    margin: 0 0 25px 0;
  }

  .productInfo a {
    width: 146px;
  }

  .rating {
    margin-bottom: 25px;
    margin-right: 0;
    display: flex;
  }

  .rating li {
    padding: 0;
    padding-right: 2px;
    padding-left: 2px;
  }

  .rating li:first-child {
    padding-right: 0;
  }

  .rating li:last-child {
    padding-left: 0;
  }

  .rating li i {
    color: #666666;
  }

  .productImage {
    position: relative;
  }

  @media (min-width: 320px) {
    .productImage {
      margin: 0 0 30px 0;
    }
  }

  @media (min-width: 768px) {
    .productImage {
      margin: 0;
    }
  }

  .productImage a {
    opacity: 0;
  }

  .productImage a i {
    width: 50px;
    height: 50px;
    line-height: 50px;
    background-color: #404040;
    position: absolute;
    color: #ffffff;
    right: 50%;
    top: 50%;
    text-align: center;
    transform: translate(50%, -30%);
    transition: all 0.4s;
  }

  .productImage a:hover i {
    background-color: #ec5598;
  }

  .productSection {
    padding: 0 0 100px 0;
    width: 100%;
    float: none;
    background-color: #ffffff;
  }

  .productSection:hover .productImage a {
    opacity: 1;
  }

  .productSection .owl-stage-outer {
    border-bottom-color: transparent;
  }

  .productSection .owl-nav {
    opacity: 1;
    top: -10px;
  }

  @media (min-width: 768px) {
    .productSection .owl-nav {
      top: 20px;
    }
  }

  .productSection .owl-nav .owl-prev {
    float: inherit;
  }

  .productSection .owl-nav .owl-prev:before {
    position: relative;
    content: "\f177";
    font-family: 'FontAwesome';
    color: #ec5598;
    font-size: 20px;
  }

  @media (min-width: 768px) {
    .productSection .owl-nav .owl-prev {
      transform: translateX(30px);
      float: left;
    }
  }

  .productSection .owl-nav .owl-next {
    float: inherit;
  }

  .productSection .owl-nav .owl-next:before {
    position: relative;
    content: "\f178";
    font-family: 'FontAwesome';
    color: #ec5598;
    font-size: 20px;
  }

  @media (min-width: 768px) {
    .productSection .owl-nav .owl-next {
      transform: translateX(0);
      position: absolute;
      left: 0;
    }
  }

  .productSection .owl-carousel .owl-controls .owl-nav .owl-prev, .productSection .owl-carousel .owl-controls .owl-nav .owl-next, .productSection .owl-carousel .owl-controls .owl-dot {
    background-color: transparent;
    box-shadow: none;
    padding: 4px;
  }

  .productSection .owl-next span,
  .productSection .owl-prev span {
    display: none;
  }

  .expertSection .owl-stage-outer {
    border-bottom-color: transparent;
  }

  .expertSection .owl-nav {
    display: none;
  }

  .expertSection .owl-carousel .owl-controls .owl-nav .owl-prev, .expertSection .owl-carousel .owl-controls .owl-nav .owl-next, .expertSection .owl-carousel .owl-controls .owl-dot {
    box-shadow: none;
    padding: 0;
  }

  .expertSection .owl-theme .owl-dots .owl-dot span {
    width: 14px;
    height: 14px;
    border: 1px solid #ec5598;
    background-color: transparent;
  }

  .expertSection .owl-theme .owl-dots .owl-dot.active span, .expertSection .owl-theme .owl-dots .owl-dot:hover span {
    background-color: #ec5598;
  }

  /*================= 5.5 REVIEW SECTION ==================*/
  .reviewSection {
    padding: 0 0 80px 0;
    width: 100%;
    float: none;
  }

  .reviewSection .secotionTitle {
    margin-bottom: 100px;
  }

  @media (min-width: 992px) {
    .reviewSection .secotionTitle {
      margin-bottom: 0;
    }
  }

  .reviewInner {
    position: relative;
  }

  .reviewImage {
    position: absolute;
    top: 0;
    right: 50%;
    width: 165px;
    height: 160px;
    border: 5px solid rgba(255, 255, 255, 0.7);
    transform: translate(40%, -50%);
  }

  @media (min-width: 992px) {
    .reviewImage {
      top: 80px;
      right: auto;
      transform: translate(0, 0);
    }
  }

  .reviewImage img {
    position: absolute;
    right: 25px;
    top: -25px;
  }

  .reviewInfo {
    padding: 60px 65px 53px 30px;
    background-color: #ffffff;
  }

  @media (min-width: 320px) {
    .reviewInfo {
      margin: 0 0 140px 0;
    }
  }

  @media (min-width: 992px) {
    .reviewInfo {
      margin: 0;
      margin-right: 160px;
    }
  }

  .reviewInfo .fa-quote-left {
    font-size: 25px;
    margin: 0 0 25px 0;
    color: #ec5598;
  }

  .reviewInfo p {
    font-size: 14px;
    line-height: 26px;
    color: #222222;
    margin: 0 0 25px 0;
  }

  .reviewInfo h3 {
    font-size: 16px;
    line-height: 26px;
    color: #222222;
    margin: 0 0 5px 0;
    font-weight: 400;
    text-transform: uppercase;
  }

  .reviewInfo h4 {
    font-size: 13px;
    line-height: 26px;
    color: #ec5598;
    margin: 0;
    font-weight: 400;
    text-transform: capitalize;
    font-family: 'Open Sans', sans-serif;
  }

  /*================= 5.6 CALL TO ACTION SECTION ==================*/
  .callAction {
    padding: 60px 0;
    background-color: #ec5598;
  }

  .callInfo p {
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
  }

  @media (min-width: 320px) {
    .callInfo p {
      margin: 0 0 20px 0;
    }
  }

  @media (min-width: 768px) {
    .callInfo p {
      margin: 0;
    }
  }

  .btn-primary.callBtn {
    background-color: #ffffff;
    color: #222222;
    width: 178px;
    display: block;
  }

  @media (min-width: 320px) {
    .btn-primary.callBtn {
      margin: 0;
    }
  }

  @media (min-width: 768px) {
    .btn-primary.callBtn {
      margin: 0 auto;
    }
  }

  .btn-primary.callBtn:hover, .btn-primary.callBtn:focus {
    background-color: #ffffff;
    color: #222222;
  }

  /*================= 5.7 PRICING SECTION ==================*/
  .pricingSection {
    padding: 0 0 80px 0;
  }

  .priceImage {
    position: relative;
    width: 100%;
    display: block;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media (min-width: 320px) {
    .priceImage {
      height: 190px;
    }
  }

  @media (min-width: 768px) {
    .priceImage {
      height: 150px;
    }
  }

  @media (min-width: 992px) {
    .priceImage {
      height: 190px;
    }
  }

  .priceImage img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
      object-fit: cover;
  }

  .maskImage {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    text-align: center;
    transition: all 0.4s;
  }

  .maskImage h3 {
    margin: 0;
    line-height: 190px;
    font-weight: 700;
    color: #ffffff;
  }

  @media (min-width: 320px) {
    .maskImage h3 {
      line-height: 190px;
      font-size: 24px;
    }
  }

  @media (min-width: 768px) {
    .maskImage h3 {
      line-height: 150px;
      font-size: 16px;
    }
  }

  @media (min-width: 992px) {
    .maskImage h3 {
      line-height: 190px;
      font-size: 24px;
    }
  }

  .priceTag {
    background-color: #ec5598;
    border-radius: 100%;
    text-align: center;
    border: 5px solid #ffffff;
    position: absolute;
    right: 50%;
  }

  @media (min-width: 320px) {
    .priceTag {
      width: 115px;
      height: 115px;
      bottom: -55px;
      margin-right: -50px;
    }
  }

  @media (min-width: 768px) {
    .priceTag {
      width: 95px;
      height: 95px;
      bottom: -45px;
      margin-right: -45px;
    }
  }

  @media (min-width: 992px) {
    .priceTag {
      width: 115px;
      height: 115px;
      bottom: -55px;
      margin-right: -50px;
    }
  }

  .priceTag h4 {
    margin: 0;
    color: #ffffff;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
  }

  @media (min-width: 320px) {
    .priceTag h4 {
      line-height: 105px;
      font-size: 35px;
    }
  }

  @media (min-width: 768px) {
    .priceTag h4 {
      line-height: 85px;
      font-size: 25px;
    }
  }

  @media (min-width: 992px) {
    .priceTag h4 {
      line-height: 105px;
      font-size: 35px;
    }
  }

  .priceInfo {
    width: 100%;
    background-color: #f5f5f5;
    padding: 60px 0 30px 0;
    text-align: center;
    border: 1px solid #dedede;
    border-top: 0;
  }

  @media (min-width: 320px) {
    .priceInfo {
      margin: 0 0 50px 0;
    }
  }

  @media (min-width: 992px) {
    .priceInfo {
      margin: 0;
    }
  }

  .priceInfo ul {
    margin-bottom: 10px;
  }

  .priceInfo ul li {
    border-bottom: 1px solid #dedede;
    padding: 21px 0;
    color: #666666;
  }

  @media (min-width: 768px) {
    .priceInfo ul li {
      font-size: 12px;
    }
  }

  @media (min-width: 992px) {
    .priceInfo ul li {
      font-size: 14px;
    }
  }

  .priceInfo ul li:last-child {
    border-bottom: none;
  }

  .priceInfo a {
    width: 133px;
  }

  .priceTableWrapper {
    box-shadow: 0 7px 21px rgba(0, 0, 0, 0);
    transition: all 0.4s;
  }

  .priceTableWrapper:hover {
    box-shadow: 0 7px 21px rgba(0, 0, 0, 0.1);
  }

  .priceTableWrapper:hover .priceInfo {
    border: 0;
  }

  .priceTableWrapper:hover .maskImage {
    background-color: rgba(236, 85, 152, 0.3);
  }

  /*================= 5.8 CONTACT US SECTION ==================*/
  .contactSection {
    padding: 0 0 0px 0;
    background-color: #ffffff;
  }

  .contactTitle h3 {
    font-weight: 400;
    font-size: 20px;
    color: #222222;
    margin: 0 0 25px 0;
  }

  .holdingInfo {
    padding: 40px 30px 30px 50px;
    margin: 45px 0 0 0;
  }

  .holdingInfo p {
    margin: 0 0 30px 0;
  }

  .holdingInfo ul li {
    font-size: 14px;
    line-height: 26px;
    color: #666666;
    position: relative;
    margin: 0 0 18px 0;
    padding: 0 25px 0 0;
  }

  @media (min-width: 992px) {
    .service {
      padding-top: 0px;
    }
    .holdingInfo ul li {
      padding: 0 38px 0 0;
    }
  }

  .holdingInfo ul li i {
    position: absolute;
    right: 0;
    top: 5px;
    color: #ec5598;
    font-size: 18px;
  }

  .holdingInfo ul li:last-child {
    margin: 0;
  }

  .holdingInfo ul li a {
    font-size: 14px;
    line-height: 26px;
    color: #666666;
  }

  .mapArea {
    width: 100%;
    display: block;
  }

  .mapArea #map {
    width: 100%;
    height: 420px;
  }

  /*================= 5.9 PARTNER LOGO SECTION ==================*/
  .brandArea {
    padding: 55px 0;
  }

  .brandArea .slide {
    display: table;
    width: 100%;
    height: 115px;
  }

  .brandArea .slide .partnersLogo {
    width: 270px;
    display: table-cell;
    vertical-align: middle;
    text-align: center !important;
  }

  .brandArea .slide .partnersLogo img {
    width: auto;
    margin: 0 auto;
    line-height: 115px;
  }

  .brandArea .owl-stage-outer {
    border-bottom: none;
  }

  .brandArea .owl-next, .brandArea .owl-prev {
    transition: all 0.4s;
    background-color: transparent !important;
    box-shadow: none !important;
    border: none;
    width: 40px;
    height: 40px;
    padding: 0 !important;
    position: absolute;
  }

  .brandArea .owl-next span, .brandArea .owl-prev span {
    display: none;
  }

  .brandArea .owl-next:before {
    color: #716d6e;
    content: "\f105";
    display: inline-block;
    font-family: FontAwesome;
    font-size: 40px;
    font-weight: 700;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 40px;
  }

  .brandArea .owl-prev:before {
    color: #716d6e;
    line-height: 40px;
    content: "\f104";
    display: inline-block;
    font-family: FontAwesome;
    font-size: 40px;
    font-weight: 700;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /*================= 5.10 HOME GALLERY SECTION ==================*/
  .homeGalleryTitle {
    width: 100%;
    float: none;
  }

  .homeGallery {
    padding: 0;
  }

  .homeGallery .row {
    margin: 0;
    justify-content: center;
  }

  .homeGallery .col-sm-3, .homeGallery .col-sm-4 {
    padding: 0;
  }

  @media (min-width: 320px) {
    .service {
      padding-top: 25px;
    }
    .service-row {
      margin-bottom: 0px;
    }
    .homeGallery ul.filter > li {
      padding: 0 10px;
      margin-bottom: 25px;
    }
  }

  @media (min-width: 768px) {
    .service-row {
      margin-bottom: 45px;
    }
    .homeGallery ul.filter > li {
      padding: 0 20px;
      margin-bottom: 0;
    }
  }

  .homeGallery ul.filter > li > a {
    padding: 0;
    border-bottom: 2px solid transparent;
    font-size: 14px;
    line-height: 26px;
    color: #6b6b6b;
    text-transform: uppercase;
    font-weight: 600;
    padding-bottom: 10px;
  }

  .homeGallery ul.filter > li > a:hover {
    background-color: transparent;
    border-bottom: 2px solid rgba(236, 85, 152, 0.5);
    color: #ec5598;
  }

  .homeGallery ul.filter > li.active > a {
    background-color: transparent;
    border-bottom: 2px solid rgba(236, 85, 152, 0.5);
    color: #ec5598;
  }

  .homeGallery.gallery-v1 .col-sm-4, .homeGallery.gallery-v1 .col-sm-3 {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
  }

  .homeGallery.gallery-v1 .col-sm-4:last-child, .homeGallery.gallery-v1 .col-sm-3:last-child {
    margin-bottom: 0;
  }

  .isotopeFilters .list-inline > li {
    display: inline-block;
  }

  .filter-container {
    padding: 0 20px 50px 20px;
  }

  figure {
    position: relative;
    height: auto;
  }

  .fancybox-bg {
    background-color: rgba(236, 85, 152, 0.5);
    // background-image: none;
  }

  .fancybox-image-wrap img {
    border: 15px solid #ffffff;
  }

  .isotopeSelector .card {
    overflow: hidden;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  }

  .isotopeSelector .card .card-img {
    position: relative;
  }

  .isotopeSelector .card .card-img img {
    max-width: 100%;
  }

  .isotopeSelector .card .card-img .overlay-content {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  .isotopeSelector .card .card-img .overlay-content a {
    display: block;
  }

  .isotopeSelector .card .card-img .overlay-content h5 {
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
  }

  .isotopeSelector .card .card-img .overlay-content h5 i {
    margin-bottom: 15px;
  }

  .isotopeSelector .card:hover .card-img .overlay-content {
    visibility: visible;
    opacity: 1;
  }

  /*================= 5.11 EXPERT SECTION ==================*/

  .expertSection .carousel-control {
    display: none;
  }

  .expertSection .item-inner {
    width: 100%;
    float: right;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (min-width: 768px) {
    .expertSection .item-inner {
      width: 25%;
    }
  }

  @media (min-width: 320px) {
    .expertSection .carousel-inner {
      min-height: 445px;
    }
  }

  @media (min-width: 768px) {
    .expertSection .carousel-inner {
      min-height: 340px;
    }
  }

  @media (min-width: 992px) {
    .expertSection .carousel-inner {
      min-height: 445px;
    }
  }

  .expertSection .carousel-indicators li {
    width: 10px;
    height: 10px;
    border: 1px solid #ec5598;
    border-radius: 10px;
    opacity: 1;
    background-clip: border-box;
  }

  .expertSection .carousel-indicators li.active {
    width: 14px;
    height: 14px;
    background-color: #ec5598;
    margin-top: -2px;
  }

  @media (min-width: 320px) {
    .expertSection .carousel-indicators {
      bottom: -35px;
    }
  }

  @media (min-width: 768px) {
    .expertSection .carousel-indicators {
      bottom: 0;
    }
  }

  .expertImage {
    overflow: hidden;
    border-radius: 100%;
    border: 7px solid #ffffff;
    position: relative;
    box-shadow: 0 7px 21px rgba(0, 0, 0, 0.3);
    margin: 0 auto;
  }

  @media (min-width: 320px) {
    .expertImage {
      width: 263px;
      height: 263px;
    }
  }

  @media (min-width: 768px) {
    .expertImage {
      width: 140px;
      height: 140px;
    }
  }

  @media (min-width: 992px) {
    .expertImage {
      width: 200px;
      height: 200px;
    }
  }

  @media (min-width: 1200px) {
    .expertImage {
      width: 230px;
      height: 230px;
    }
  }

  .expertImage img {
    max-width: 100%;
  }

  .expertMask {
    width: 100%;
    height: 100%;
    background-color: rgba(236, 85, 152, 0.5);
    position: absolute;
    border-radius: 100%;
    right: 0;
    top: 0;
    opacity: 0;
    transition: all 0.4s;
  }

  .socialArea {
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 60px;
  }

  @media (min-width: 768px) {
    .socialArea {
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
    }
  }

  @media (min-width: 992px) {
    .socialArea {
      top: auto;
      bottom: 40px;
      transform: translateY(0);
    }
  }

  @media (min-width: 1200px) {
    .socialArea {
      bottom: 60px;
    }
  }

  .socialArea ul {
    text-align: center;
  }

  .socialArea ul li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
  }

  @media (min-width: 768px) {
    .socialArea ul li {
      padding-right: 4px;
      padding-left: 4px;
    }
  }

  @media (min-width: 992px) {
    .socialArea ul li {
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  .socialArea ul li a {
    color: #ffffff;
  }

  .expertCaption {
    margin-top: 25px;
  }

  .expertCaption h2 {
    margin: 0 0 10px 0;
    font-size: 20px;
    line-height: 26px;
    color: #222222;
    font-weight: 400;
    transition: all 0.4s;
  }

  .expertCaption p {
    margin: 0;
  }

  @media (min-width: 768px) {
    .expertCaption p {
      font-size: 11px;
    }
  }

  @media (min-width: 992px) {
    .expertCaption p {
      font-size: 14px;
    }
  }

  .expertBox {
    margin-top: 15px;
    margin-bottom: 25px;
    text-align: center;
  }

  .expertBox:hover .expertMask {
    opacity: 1;
  }

  .expertBox:hover .expertCaption h2 {
    color: #ec5598;
  }

  .page-expert .item .expertBox {
    margin-bottom: 40px;
  }

  .page-expert .item:last-child .expertBox {
    margin-bottom: 0;
  }

  footer {
    background-attachment: fixed;
    width: 100%;
    background-size: cover;
    background-position: center center;
    padding-bottom: 0;
    position: relative;
  }

  /*=== 4.2 FOOTER TOP ===*/
  .footerInfo {
    padding: 32px 0 18px 0;
    border-top: 4px solid #ec5598;
  }

  @media (min-width: 320px) {
    .footerInfo .col-sm-3 {
      padding: 0 15px 0px 15px;
    }
  }

  @media (min-width: 768px) {
    .footerInfo .col-sm-3 {
      padding: 0 15px;
    }
  }

  @media (min-width: 320px) {
    .footerInfo .paddingLeft {
      padding-right: 15px;
    }
  }

  @media (min-width: 768px) {
    .footerInfo .paddingLeft {
      padding-right: 15px;
    }
  }

  @media (min-width: 992px) {
    .footerInfo .paddingLeft {
      padding-right: 40px;
    }
  }

  .footerText .footerLogo {
    margin-bottom: 40px;
    display: block;
  }

  .footerText .footerLogo svg {
    width: 70%;
  }

  @media (min-width: 768px) {
    .footerText .footerLogo svg {
      width: 100%;
    }
  }

  .footerText .footerLogo .fill-primary {
    fill: #ec5598;
  }

  .footerText p {
    line-height: 24px;
    color: #666666;
    margin-bottom: 30px;
  }

  @media (min-width: 320px) {
    .footerText p {
      font-size: 14px;
    }
  }

  @media (min-width: 480px) {
    .footerText p {
      font-size: 14px;
    }
  }

  @media (min-width: 768px) {
    .footerText p {
      font-size: 12px;
    }
  }

  @media (min-width: 992px) {
    .footerText p {
      font-size: 14px;
    }
  }

  .footerText .input-group {
    width: 100%;
  }

  .footerText .form-control, .footerText .input-group-addon {
    width: 65%;
    border-radius: 0;
    height: 40px;
    box-shadow: none;
    color: #666666;
    background-color: #ffffff;
    border: 1px solid #ffffff;
  }

  @media (min-width: 320px) {
    .footerText .form-control, .footerText .input-group-addon {
      font-size: 14px;
    }
  }

  @media (min-width: 480px) {
    .footerText .form-control, .footerText .input-group-addon {
      font-size: 14px;
    }
  }

  @media (min-width: 768px) {
    .footerText .form-control, .footerText .input-group-addon {
      font-size: 12px;
    }
  }

  @media (min-width: 992px) {
    .footerText .form-control, .footerText .input-group-addon {
      font-size: 14px;
    }
  }

  .footerText .form-control:focus {
    border: 1px solid #ec5598;
  }

  .footerText .input-group-addon {
    width: 35%;
    background-color: #ec5598;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    border: 1px solid #ec5598;
  }

  .footerInfoTitle h4 {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 35px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
  }

  @media (min-width: 320px) {
    .useLink ul li {
      margin: 0 0 10px 0;
    }
  }

  @media (min-width: 480px) {
    .useLink ul li {
      margin: 0 0 10px 0;
    }
  }

  @media (min-width: 768px) {
    .useLink ul li {
      margin: 0 0 10px 0;
    }
  }

  @media (min-width: 992px) {
    .useLink ul li {
      margin: 0 0 20px 0;
    }
  }

  .useLink ul li a {
    line-height: 24px;
    color: #666666;
  }

  .useLink ul li a i {
    margin-left: 10px;
  }

  .useLink ul li a:hover {
    color: #ec5598;
  }

  @media (min-width: 320px) {
    .useLink ul li a {
      font-size: 14px;
    }
  }

  @media (min-width: 480px) {
    .useLink ul li a {
      font-size: 14px;
    }
  }

  @media (min-width: 768px) {
    .useLink ul li a {
      font-size: 12px;
    }
  }

  @media (min-width: 992px) {
    .useLink ul li a {
      font-size: 14px;
    }
  }

  @media (min-width: 320px) {
    .footerGallery {
      padding-right: 0;
    }
  }

  @media (min-width: 768px) {
    .footerGallery {
      padding-right: 15px;
    }
  }

  @media (min-width: 320px) {
    .footerGallery .col-xs-4 {
      padding: 0 15px 15px 15px;
    }
  }

  @media (min-width: 768px) {
    .footerGallery .col-xs-4 {
      padding: 0 0 10px 10px;
    }
  }

  @media (min-width: 768px) {
    .footerGallery .col-4 {
      padding: 0 0 10px 10px;
    }
  }

  .footerGallery a {
    display: block;
    width: 100%;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 75px;
  }

  .footerGallery a img {
    width: 100%;
    height: 75px;
    -o-object-fit: cover;
      object-fit: cover;
  }

  /*=== 4.2 COPY RIGHT ===*/
  .copyRight {
    border: 1px solid rgba(0, 0, 0, 0.07);
    padding: 30px 0;
  }

  .copyRight .list-inline {
    text-align: center;
    margin: 0 0 25px 0;
  }

  @media (min-width: 768px) {
    .copyRight .list-inline {
      text-align: left;
      margin: 0;
    }
  }

  .copyRight .list-inline li a {
    color: #666666;
    font-size: 13px;
    padding: 0 5px;
  }

  .copyRight .list-inline li a:hover {
    color: #ec5598;
  }

  .copyRightText p {
    margin-bottom: 20px;
    color: #666666;
    margin-bottom: 0;
    text-align: center;
  }

  @media (min-width: 768px) {
    .copyRightText p {
      margin-bottom: 0;
      text-align: right;
    }
  }

  .copyRightText p a {
    color: #666666;
    text-decoration: underline !important;
  }

  .copyRightText p a:hover {
    color: #ec5598;
  }

  /*================= 6. NOT FOUND ==================*/
  .notFound {
    background-color: #ffffff;
    text-align: center;
  }

  @media (min-width: 320px) {
    .notFound {
      padding: 50px 15px 50px 15px;
    }
  }

  @media (min-width: 768px) {
    .notFound {
      padding: 100px 15px 100px 15px;
    }
  }

  .notFoundLogo {
    display: inline-block;
  }

  @media (min-width: 320px) {
    .notFoundLogo {
      margin-bottom: 40px;
    }
  }

  @media (min-width: 768px) {
    .notFoundLogo {
      margin-bottom: 80px;
    }
  }

  .notFoundImage {
    text-align: center;
  }

  @media (min-width: 320px) {
    .notFoundImage {
      margin-bottom: 40px;
    }
  }

  @media (min-width: 768px) {
    .notFoundImage {
      margin-bottom: 80px;
    }
  }

  .notFoundImage img {
    display: inline-block;
  }

  .notFoundInfo h2 {
    font-weight: 400;
    font-size: 20px;
  }

  @media (min-width: 320px) {
    .notFoundInfo h2 {
      margin-bottom: 35px;
    }
  }

  @media (min-width: 768px) {
    .notFoundInfo h2 {
      margin-bottom: 70px;
    }
  }

  .notFoundInfo .btn-primary {
    padding-right: 30px;
    padding-left: 30px;
    font-size: 14px;
  }

  /*=== 7. COMING SOON ===*/
  .comingSoon {
    text-align: right;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 0;
    background-position: 85% center;
  }

  @media (min-width: 768px) {
    .comingSoon {
      background-position: center center;
    }
  }

  .comingSoon:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .comingSoonInner {
    width: 100%;
  }

  @media (min-width: 768px) {
    .comingSoonInner {
      float: left;
      width: 58.33333333%;
    }
  }

  @media (min-width: 992px) {
    .comingSoonInner {
      width: 41.66666667%;
    }
  }

  .comingInner {
    position: relative;
  }

  @media (min-width: 320px) {
    .comingInner {
      transform: translate(0, 50%);
    }
  }

  @media (min-width: 768px) {
    .comingInner {
      transform: translate(0, 50%);
    }
  }

  @media (min-width: 992px) {
    .comingInner {
      transform: translate(0, 50%);
    }
  }

  .comingInner p {
    font-size: 17px;
    color: #202020;
    margin: 0 0 30px 0;
    text-align: center;
  }

  @media (min-width: 768px) {
    .comingInner p {
      text-align: right;
      font-size: 20px;
    }
  }

  .comingInner .input-group {
    width: 100%;
  }

  .comingInner .form-control, .comingInner .input-group-addon {
    width: 65%;
    border-radius: 0;
    height: 40px;
    box-shadow: none;
    color: #666666;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin: 0 0 0 5%;
  }

  @media (min-width: 320px) {
    .comingInner .form-control, .comingInner .input-group-addon {
      font-size: 10px;
    }
  }

  @media (min-width: 480px) {
    .comingInner .form-control, .comingInner .input-group-addon {
      font-size: 14px;
    }
  }

  @media (min-width: 768px) {
    .comingInner .form-control, .comingInner .input-group-addon {
      font-size: 12px;
    }
  }

  @media (min-width: 992px) {
    .comingInner .form-control, .comingInner .input-group-addon {
      font-size: 14px;
    }
  }

  .comingInner .form-control:focus {
    border: 1px solid #ec5598;
  }

  .comingInner .input-group-addon {
    width: 30%;
    background-color: #ec5598;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    border: 1px solid #ec5598;
    margin: 0;
  }

  .comingLogo {
    width: 210px;
    margin: 0 auto 35px;
  }

  @media (min-width: 768px) {
    .comingLogo {
      margin: 0 0 35px 0;
    }
  }

  .comingLogo a {
    display: inline-block;
  }

  .comingLogo img {
    width: 100%;
  }

  .comingTitle {
    text-align: center;
  }

  @media (min-width: 768px) {
    .comingTitle {
      text-align: right;
    }
  }

  .comingTitle h2 {
    font-size: 30px;
    font-weight: 400;
    text-transform: capitalize;
    margin: 0 0 40px 0;
  }

  @media (min-width: 768px) {
    .comingTitle h2 {
      font-size: 35px;
    }
  }

  .count-down {
    margin: 0 0 35px 0;
    text-align: center;
  }

  @media (min-width: 768px) {
    .count-down {
      text-align: right;
    }
  }

  .count-down #simple_timer {
    width: 100%;
    margin: 0 auto;
    color: #222222;
  }

  .count-down #simple_timer .table-cell {
    display: inline-block;
    margin: 0 0 0 3px;
  }

  @media (min-width: 480px) {
    .count-down #simple_timer .table-cell {
      margin: 0 0 0 22px;
    }
  }

  @media (min-width: 768px) {
    .count-down #simple_timer .table-cell {
      margin: 0 0 0 18px;
    }
  }

  @media (min-width: 992px) {
    .count-down #simple_timer .table-cell {
      margin: 0 0 0 15px;
    }
  }

  .count-down #simple_timer .table-cell:last-child {
    margin: 0;
  }

  .count-down #simple_timer .table-cell .tab-val {
    font-weight: 600;
    display: block;
    background-color: #ffffff;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  @media (min-width: 320px) {
    .count-down #simple_timer .table-cell .tab-val {
      width: 66px;
      height: 66px;
      line-height: 66px;
      font-size: 20px;
    }
  }

  @media (min-width: 480px) {
    .count-down #simple_timer .table-cell .tab-val {
      width: 85px;
      height: 85px;
      line-height: 85px;
      font-size: 25px;
    }
  }

  @media (min-width: 768px) {
    .count-down #simple_timer .table-cell .tab-val {
      width: 85px;
      height: 85px;
      line-height: 85px;
      font-size: 25px;
    }
  }

  @media (min-width: 992px) {
    .count-down #simple_timer .table-cell .tab-val {
      width: 85px;
      height: 85px;
      line-height: 85px;
      font-size: 30px;
    }
  }

  @media (min-width: 1200px) {
    .count-down #simple_timer .table-cell .tab-val {
      width: 100px;
      height: 100px;
      line-height: 100px;
      font-size: 40px;
    }
  }

  .count-down #simple_timer .table-cell .tab-metr {
    display: none;
  }

  /*=== 8. BUSINESS ===*/
  /*=== 8.1 ORDER SECTION ===*/
  @media (min-width: 320px) {
    .orderArea {
      padding: 50px 0 0 0;
    }
  }

  @media (min-width: 768px) {
    .orderArea {
      padding: 100px 0 0 0;
    }
  }

  .orderSection .cartInfo .panel-heading {
    font-family: 'Open Sans', sans-serif;
  }

  .cartInfo {
    border-radius: 0;
    border-color: #dedede;
    border: none;
  }

  .cartInfo .panel-heading {
    border-radius: 0;
    height: 56px;
    padding: 0 30px;
    line-height: 56px;
    font-weight: 400;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    color: #222222;
    letter-spacing: 1px;
    border-bottom: none;
  }

  @media (min-width: 320px) {
    .cartInfo .panel-heading {
      font-size: 14px;
    }
  }

  @media (min-width: 768px) {
    .cartInfo .panel-heading {
      font-size: 20px;
    }
  }

  .cartInfo .panel-heading span {
    color: #ec5598;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
  }

  .cartInfo .panel-body {
    padding: 20px;
    border: 1px solid #dedede;
    border-top: none;
  }

  .cartInfo .panel-body.tableArea {
    padding: 0;
  }

  .cartInfo table {
    margin: 0;
  }

  .cartInfo table tr td {
    border-top: none;
    border-bottom: 1px solid #dedede;
    font-family: 'Montserrat', sans-serif;
    line-height: 26px;
    color: #000000;
    vertical-align: middle;
  }

  @media (min-width: 320px) {
    .cartInfo table tr td {
      font-size: 14px;
      padding: 5px;
    }
  }

  @media (min-width: 768px) {
    .cartInfo table tr td {
      font-size: 18px;
      padding: 20px;
    }
  }

  .cartInfo table tr td a {
    font-family: 'Montserrat', sans-serif;
    line-height: 26px;
    color: #000000;
    white-space: nowrap;
  }

  @media (min-width: 320px) {
    .cartInfo table tr td a {
      font-size: 14px;
    }
  }

  @media (min-width: 768px) {
    .cartInfo table tr td a {
      font-size: 18px;
    }
  }

  .cartInfo table tr td .btn {
    font-size: 14px;
    color: #666666;
    line-height: 20px;
    width: 150px;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
  }

  .cartInfo table tr td .btn:hover, .cartInfo table tr td .btn:focus {
    color: #ffffff;
    background-color: #ec5598;
  }

  .cartInfo table tr td span {
    color: #666666;
    font-size: 13px;
    line-height: 24px;
    font-family: 'Open Sans', sans-serif;
  }

  .cartInfo table tr td .price {
    color: #ec5598;
    font-size: 18px;
    line-height: 24px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
  }

  .cartInfo table tr td .close {
    opacity: 1;
    line-height: 45px;
  }

  .cartInfo table tr td .close span {
    font-size: 45px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    color: #666666;
  }

  @media (min-width: 320px) {
    .cartInfo table tr td:first-child {
      width: auto;
    }
  }

  @media (min-width: 768px) {
    .cartInfo table tr td:first-child {
      width: 155px;
    }
  }

  .cartInfo table tr td:last-child {
    text-align: left;
  }

  .cartInfo table tr:last-child td {
    border: none;
  }

  .cartInfo p {
    color: #222222;
    margin-bottom: 18px;
  }

  .cartInfo .input-group {
    width: 100%;
    margin-bottom: 20px;
  }

  .cartInfo .form-control, .cartInfo .input-group-addon {
    border-radius: 0;
    height: 40px;
    box-shadow: none;
    color: #666666;
    background-color: #ffffff;
    border: 1px solid #dedede;
    margin: 0 0 0 5%;
  }

  @media (min-width: 320px) {
    .cartInfo .form-control, .cartInfo .input-group-addon {
      font-size: 14px;
      width: 65%;
    }
  }

  @media (min-width: 768px) {
    .cartInfo .form-control, .cartInfo .input-group-addon {
      font-size: 12px;
      width: 50%;
    }
  }

  @media (min-width: 992px) {
    .cartInfo .form-control, .cartInfo .input-group-addon {
      font-size: 14px;
      width: 65%;
    }
  }

  .cartInfo .form-control:focus {
    border: 1px solid #ec5598;
  }

  .cartInfo .input-group-addon {
    background-color: transparent;
    color: #222222;
    font-weight: 700;
    text-transform: uppercase;
    border: 1px solid #ec5598;
    margin: 0;
  }

  @media (min-width: 320px) {
    .cartInfo .input-group-addon {
      width: 30%;
    }
  }

  @media (min-width: 768px) {
    .cartInfo .input-group-addon {
      width: 45%;
    }
  }

  @media (min-width: 992px) {
    .cartInfo .input-group-addon {
      width: 30%;
    }
  }

  .cartImage {
    height: 105px;
    border: 1px solid #dedede;
    text-align: center;
    line-height: 105px;
    display: block;
  }

  @media (min-width: 320px) {
    .cartImage {
      width: 105px;
    }
  }

  @media (min-width: 768px) {
    .cartImage {
      width: 130px;
    }
  }

  /*=== 8.2 CHECK OUT ===*/
  @media (min-width: 320px) {
    .checkout {
      padding: 50px 0;
    }
  }

  @media (min-width: 768px) {
    .checkout {
      padding: 100px 0;
    }
  }

  .orderSection .table-responsive {
    overflow-x: auto;
    width: 100%;
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    .orderSection .table-responsive {
      overflow-x: auto;
    }
  }

  .checkInfo {
    border-radius: 0;
    border-color: #dedede;
    border: none;
    margin-bottom: 80px;
  }

  .checkInfo .panel-heading {
    border-radius: 0;
    height: 56px;
    padding: 0 30px;
    line-height: 56px;
    font-weight: 400;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    color: #222222;
    letter-spacing: 1px;
    border-bottom: none;
  }

  @media (min-width: 320px) {
    .checkInfo .panel-heading {
      font-size: 14px;
    }
  }

  @media (min-width: 768px) {
    .checkInfo .panel-heading {
      font-size: 20px;
    }
  }

  .checkInfo .panel-body {
    padding: 20px;
    border: 1px solid #dedede;
    border-top: none;
  }

  .cardTitle {
    color: #000000;
    margin-bottom: 20px;
  }

  @media (min-width: 320px) {
    .cardTitle {
      padding-right: 0;
    }
  }

  @media (min-width: 768px) {
    .cardTitle {
      padding-right: 0;
    }
  }

  .cardTitle i.fa {
    color: #ec5598;
    margin-left: 10px;
  }

  @media (min-width: 320px) {
    .cardTitle.extraSpace {
      padding-right: 0;
    }
  }

  @media (min-width: 768px) {
    .cardTitle.extraSpace {
      padding-right: 20px;
    }
  }

  .form-group .cardTitle {
    padding-right: 0;
  }

  .form-group .cardTitle i.fa {
    color: #666666;
    margin-left: 10px;
  }

  @media (min-width: 320px) {
    .commentsForm {
      padding: 0 0 20px 0;
      border-bottom: 1px dashed #b7b7b7;
      margin: 0 0 20px 0;
    }
  }

  @media (min-width: 768px) {
    .commentsForm {
      padding: 0;
      margin: 0;
      border: none;
    }
  }

  .form-group {
    position: relative;
  }

  .form-group .helpText {
    position: absolute;
    top: 14px;
    left: 25px;
  }

  .form-group .dateSelect, .form-group .countrySelect {
    margin-bottom: 0;
  }

  .paymentPart {
    border: 1px solid #dedede;
    padding: 10px 20px;
  }

  .paymentPart .form-group {
    margin-bottom: 0;
  }

  @media (min-width: 320px) {
    .paymentPart .btn-primary {
      width: 100%;
      padding-right: 25px;
      padding-left: 25px;
      margin-bottom: 10px;
    }
  }

  @media (min-width: 768px) {
    .paymentPart .btn-primary {
      width: auto;
      padding-right: 25px;
      padding-left: 25px;
      margin-bottom: 0;
    }
  }

  @media (min-width: 320px) {
    .totalAmount {
      float: none;
      display: block;
      text-align: center;
    }
  }

  @media (min-width: 768px) {
    .totalAmount {
      float: left;
      display: inline-block;
    }
  }

  .totalAmount span, .totalAmount strong {
    line-height: 45px;
    font-size: 20px;
    color: #000000;
  }

  .totalAmount strong {
    color: #ec5598;
    font-size: 20px;
    margin-right: 10px;
  }

  .chooseOption {
    width: 100%;
    padding-right: 40px;
  }

  .chooseOption input[type=radio] {
    outline: 0;
  }

  /*=== 8.2 CONFIRMATION ===*/
  .confirmation .alert.alert-success {
    border: 1px solid transparent;
    background-color: #d1f0ca;
    color: #49c32c;
    font-size: 14px;
    padding: 15px 20px;
    margin: 0 0 60px 0;
    width: 100%;
    border: none;
    border-radius: 0;
  }

  @media (min-width: 768px) {
    .confirmation .alert.alert-success {
      font-size: 24px;
    }
  }

  .confirmation .alert .fa-check {
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 100%;
    background-color: #49c32c;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    margin-left: 10px;
    color: #fff;
    display: inline-block;
    text-align: center;
  }

  @media (min-width: 768px) {
    .confirmation .alert .fa-check {
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      margin-left: 24px;
    }
  }

  .confirmation .alert .close {
    position: relative;
    top: -3px;
    left: 0;
    color: #49c32c;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    filter: alpha(opacity=1);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }

  @media (min-width: 768px) {
    .confirmation .alert .close {
      top: 0;
    }
  }

  .confirm-detail {
    margin-top: 65px;
  }

  .confirm-detail h3 {
    font-size: 15px;
    margin-bottom: 0;
    font-weight: 600;
  }

  @media (min-width: 768px) {
    .confirm-detail h3 {
      display: inline-block;
      font-size: 20px;
    }
  }

  .confirm-detail h3 span {
    color: #ec5598;
  }

  .confirm-detail .cart-total {
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    color: #222222;
    font-size: 15px;
    display: inline-block;
    margin-top: 15px;
  }

  @media (min-width: 768px) {
    .confirm-detail .cart-total {
      float: left;
      margin-top: 0;
      font-size: 20px;
    }
  }

  .confirm-detail .cart-total span {
    color: #ec5598;
  }

  /*=== 9. PRODUCTS ===*/
  /*=== 9.1 RELATED PRODUCT ===*/
  @media (min-width: 320px) {
    .relatedProduct {
      padding: 50px 0 25px 0;
    }
  }

  @media (min-width: 768px) {
    .relatedProduct {
      padding: 100px 0;
    }
  }

  .relatedTitle {
    padding: 0 15px;
  }

  .relatedTitle h2 {
    letter-spacing: 1px;
  }

  .realatedInner {
    width: 100%;
    min-height: 430px;
    border: 1px solid #dedede;
    display: block;
    text-align: center;
  }

  @media (min-width: 320px) {
    .realatedInner {
      margin: 0 0 25px 0;
    }
  }

  @media (min-width: 992px) {
    .realatedInner {
      margin: 0;
    }
  }

  .productBox {
    width: 100%;
    height: 330px;
    text-align: center;
    line-height: 330px;
  }

  .productBox img {
    display: inline-block;
  }

  .productName {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: #222222;
    line-height: 26px;
    margin-bottom: 15px;
    font-weight: 400;
  }

  .productPrice {
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    color: #ec5598;
    line-height: 26px;
    margin-bottom: 15px;
    font-weight: 700;
  }

  /*=== 9.2 SINGLE SERVICE ===*/
  @media (min-width: 320px) {
    .singleService {
      padding: 50px 0 25px 0;
    }
  }

  @media (min-width: 768px) {
    .singleService {
      padding: 100px 0;
    }
  }

  @media (min-width: 320px) {
    .singleService .tab-content {
      margin: 0 0 50px 0;
    }
  }

  @media (min-width: 768px) {
    .singleService .tab-content {
      margin: 0 0 0 0;
    }
  }

  .sidebar {
    box-shadow: 0 7px 21px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    display: block;
    margin-bottom: 40px;
  }

  .sidebar.navbar-collapse.collapse {
    padding: 25px 0;
  }

  .sidebar .side-nav {
    width: 100%;
    margin: 0;
    height: auto;
  }

  .sidebar .side-nav li {
    float: none;
    display: block;
  }

  .sidebar .side-nav li a {
    border-bottom: 1px dashed #b7b7b7;
    color: #222222;
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    text-transform: uppercase;
  }

  .sidebar .side-nav li a:hover, .sidebar .side-nav li a:focus {
    background-color: transparent;
    color: #ec5598;
  }

  @media (min-width: 320px) {
    .sidebar .side-nav li a {
      padding: 15px 25px;
    }
  }

  @media (min-width: 768px) {
    .sidebar .side-nav li a {
      padding: 15px;
    }
  }

  @media (min-width: 992px) {
    .sidebar .side-nav li a {
      padding: 25px 50px;
    }
  }

  .sidebar .side-nav li a i.fa {
    float: left;
    font-size: 14px;
    line-height: 26px;
  }

  .sidebar .side-nav li:last-child a {
    border-bottom: none;
  }

  @media (min-width: 320px) {
    .sidebar .side-nav .collapseItem {
      padding: 0 25px;
    }
  }

  @media (min-width: 768px) {
    .sidebar .side-nav .collapseItem {
      padding: 0 15px;
    }
  }

  @media (min-width: 992px) {
    .sidebar .side-nav .collapseItem {
      padding: 0 50px;
    }
  }

  .sidebar .side-nav .collapseItem li {
    float: none;
    display: block;
  }

  .sidebar .side-nav .collapseItem li a {
    text-transform: capitalize;
    display: block;
  }

  @media (min-width: 320px) {
    .sidebar .side-nav .collapseItem li a {
      padding: 15px 0;
    }
  }

  @media (min-width: 768px) {
    .sidebar .side-nav .collapseItem li a {
      padding: 25px 0;
    }
  }

  .sidebar .side-nav .collapseItem li a span {
    float: left;
  }

  .nav-stacked {
    box-shadow: 0 7px 21px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    display: block;
    margin-bottom: 40px;
    padding: 0;
  }

  .nav-stacked li a {
    margin-left: 0;
    border: 0;
    border-radius: 0;
    border-bottom: 1px dashed #b7b7b7;
    color: #222222;
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    text-transform: uppercase;
    text-align: right;
    display: block;
  }

  .nav-stacked li a:hover, .nav-stacked li a:focus {
    background-color: transparent;
    color: #ec5598;
    border: 0;
    border-bottom: 1px dashed #b7b7b7;
  }

  @media (min-width: 320px) {
    .nav-stacked li a {
      padding: 15px 25px;
    }
  }

  @media (min-width: 768px) {
    .nav-stacked li a {
      padding: 15px;
    }
  }

  @media (min-width: 992px) {
    .nav-stacked li a {
      padding: 25px 50px;
    }
  }

  .nav-stacked li a i.fa {
    float: left;
    font-size: 14px;
    line-height: 26px;
  }

  .nav-stacked li.active a {
    background-color: transparent;
    color: #ec5598;
    border: 0;
    border-bottom: 1px dashed #b7b7b7;
  }

  .nav-stacked li.active a:hover, .nav-stacked li.active a:focus {
    background-color: transparent;
    color: #ec5598;
    border: 0;
    border-bottom: 1px dashed #b7b7b7;
  }

  .nav-stacked li:last-child a {
    border-bottom: none;
  }

  .nav-stacked li .dropdown-menu {
    border: 0;
    margin-top: 0;
    z-index: 0;
  }

  @media (min-width: 320px) {
    .nav-stacked li .dropdown-menu {
      padding: 0 25px;
    }
  }

  @media (min-width: 768px) {
    .nav-stacked li .dropdown-menu {
      padding: 0 15px;
    }
  }

  @media (min-width: 992px) {
    .nav-stacked li .dropdown-menu {
      padding: 0 50px;
    }
  }

  .nav-stacked li .dropdown-menu a.active:hover {
    color: black !important;
  }

  .nav-stacked li .dropdown-menu li a {
    margin-left: 0;
    border: 0;
    border-radius: 0;
    border-bottom: 1px dashed #b7b7b7;
    color: #444;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-transform: capitalize;
    text-align: right;
  }

  .nav-stacked li .dropdown-menu li a:hover, .nav-stacked li .dropdown-menu li a:focus {
    background-color: transparent;
    color: #ec5598;
    border: 0;
    border-bottom: 1px dashed #b7b7b7;
  }

  @media (min-width: 320px) {
    .nav-stacked li .dropdown-menu li a {
      padding: 15px 0;
    }
  }

  @media (min-width: 768px) {
    .nav-stacked li .dropdown-menu li a {
      padding: 25px 0;
    }
  }

  .nav-stacked li .dropdown-menu li a span {
    float: left;
  }

  .nav-stacked li .dropdown-menu li.active a {
    background-color: transparent;
    color: #ec5598;
    border: 0;
    border-bottom: 1px dashed #b7b7b7;
  }

  .nav-stacked li .dropdown-menu li.active a:hover, .nav-stacked li .dropdown-menu li.active a:focus {
    background-color: transparent;
    color: #ec5598;
    border: 0;
    border-bottom: 1px dashed #b7b7b7;
  }

  .nav-stacked li .dropdown-menu li:last-child a {
    border-bottom: 1px dashed #b7b7b7;
  }

  .nav-stacked .open > .dropdown-menu, .nav-stacked .openTab > .dropdown-menu {
    position: relative;
    box-shadow: none;
    display: block;
    float: none;
    width: 100%;
  }

  .nav-stacked .dropdown-toggle::after {
    display: none;
  }

  .nav-stacked .dropdown-menu.show {
    will-change: inherit !important;
    transform: translate3d(0, 0px, 0px) !important;
  }

  .nav-stacked .dropdown-toggle {
    padding: 25px 30px;
  }

  @media (min-width: 320px) {
    .marginAdjust {
      margin-bottom: 30px;
    }
  }

  @media (min-width: 768px) {
    .marginAdjust {
      margin-bottom: 80px;
    }
  }

  /*=== 9.3 SINGLE PRODUCT ===*/
  @media (min-width: 320px) {
    .singleProduct {
      padding: 50px 0 25px 0;
    }
  }

  @media (min-width: 768px) {
    .singleProduct {
      padding: 100px 0;
    }
  }

  .singleIamge {
    height: 550px;
    text-align: center;
    border: 1px solid #dedede;
    line-height: 550px;
  }

  @media (min-width: 320px) {
    .singleIamge {
      margin-bottom: 25px;
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    .singleIamge {
      margin-bottom: 100px;
      width: 90%;
    }
  }

  .singleIamge img {
    display: inline-block;
  }

  @media (min-width: 320px) {
    .singleProductInfo {
      margin-bottom: 25px;
    }
  }

  @media (min-width: 768px) {
    .singleProductInfo {
      margin-bottom: 100px;
    }
  }

  .singleProductInfo h2 {
    font-weight: 400;
  }

  @media (min-width: 320px) {
    .singleProductInfo h2 {
      margin: 0 0 25px 0;
    }
  }

  @media (min-width: 768px) {
    .singleProductInfo h2 {
      margin: 0 0 50px 0;
    }
  }

  .singleProductInfo h3 {
    font-size: 45px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: #ec5598;
  }

  @media (min-width: 320px) {
    .singleProductInfo h3 {
      margin: 0 0 25px 0;
    }
  }

  @media (min-width: 768px) {
    .singleProductInfo h3 {
      margin: 0 0 45px 0;
    }
  }

  .singleProductInfo h3 del {
    font-size: 35px;
    color: #e4e4e4;
    margin-right: 15px;
  }

  .singleProductInfo p {
    line-height: 26px;
  }

  @media (min-width: 320px) {
    .singleProductInfo p {
      margin: 0 0 25px 0;
    }
  }

  @media (min-width: 768px) {
    .singleProductInfo p {
      margin: 0 0 45px 0;
    }
  }

  @media (min-width: 320px) {
    .finalCart {
      margin: 0 0 25px 0;
    }
  }

  @media (min-width: 768px) {
    .finalCart {
      margin: 0 0 50px 0;
    }
  }

  .finalCart input {
    width: 65px;
    height: 45px;
    margin-left: 10px;
    text-align: center;
    border: 1px solid #dedede;
  }

  .finalCart .btn-primary {
    width: 163px;
  }

  input[type=number]::-webkit-inner-spin-button {
    opacity: 1;
    height: 45px;
  }

  @media (min-width: 320px) {
    .category {
      margin: 0 0 25px 0;
    }
  }

  @media (min-width: 768px) {
    .category {
      margin: 0 0 35px 0;
    }
  }

  .category li {
    color: #222222;
  }

  .category li a:hover {
    color: #ec5598;
  }

  .shareSocial li {
    color: #222222;
    font-family: 'Montserrat', sans-serif;
    font-size: 21px;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .shareSocial li a .fa {
    width: 35px;
    height: 35px;
    display: block;
    border-radius: 100%;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
    font-size: 16px;
  }

  .shareSocial li a .fa.fa-facebook {
    background-color: #3b5999;
  }

  .shareSocial li a .fa.fa-twitter {
    background-color: #55aced;
  }

  .shareSocial li a .fa.fa-pinterest-p {
    background-color: #bd081c;
  }

  .shareSocial li a .fa.fa-skype {
    background-color: #00aff0;
  }

  .shareSocial li a .fa.fa-linkedin {
    background-color: #0077b5;
  }

  /*===CUSTOM PRODUCT ===*/
  .custom-categories {
    margin-bottom: 30px;
  }

  .custom-categories h4 {
    background-color: #ec5598;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 45px;
    padding: 0 15px;
    margin-bottom: 0;
  }

  .custom-categories .custom-accordion {
    border: 1px solid #dedede;
    border-top-color: transparent;
    padding: 15px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  }

  .custom-categories .custom-accordion .custom-panel {
    margin-bottom: 0;
    border-color: transparent;
    box-shadow: none;
  }

  .custom-categories .custom-accordion .custom-panel h5 {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    padding: 9px 0;
    margin-bottom: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    transition: all .5s;
  }

  .custom-categories .custom-accordion .custom-panel h5:hover {
    color: #ec5598 !important;
  }

  .custom-categories .custom-accordion .custom-panel h5.collapse-icon {
    position: relative;
  }

  .custom-categories .custom-accordion .custom-panel h5.collapse-icon::before {
    position: absolute;
    content: "\f068";
    font-family: 'FontAwesome';
    left: 0;
  }

  .custom-categories .custom-accordion .custom-panel h5.collapsed {
    color: #222222;
  }

  .custom-categories .custom-accordion .custom-panel h5.collapsed::before {
    content: "\f067";
  }

  .custom-categories .custom-accordion .custom-panel .custom-panel-body {
    padding: 0 15px;
  }

  .custom-categories .custom-accordion .custom-panel .custom-panel-body a {
    color: #888;
    display: block;
    padding: 7px 0;
    font-size: 13px;
    transition: all .5s;
  }

  .custom-categories .custom-accordion .custom-panel .custom-panel-body a:hover {
    color: #ec5598;
  }

  /*=== 9.4 PRODUCT ===*/
  @media (min-width: 320px) {
    .productArea {
      padding: 50px 0;
    }
  }

  @media (min-width: 768px) {
    .productArea {
      padding: 100px 0;
    }
  }

  .productSideBar {
    border: none;
    border-radius: 0;
    margin-bottom: 30px;
  }

  .productSideBar .panel-heading {
    border: 1px solid #ec5598;
    background-color: #ec5598;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 45px;
    padding: 0 15px 0 0;
    height: 45px;
    border-radius: 0;
  }

  .productSideBar .panel-body {
    border: 1px solid #dedede;
    border-top: none;
    position: relative;
  }

  .productSideBar .panel-body .navbar-side-collapse {
    padding: 0;
    display: block;
  }

  .productSideBar .panel-body .navbar-side-collapse .navbar-nav {
    height: auto;
    margin: 0;
  }

  .productSideBar .panel-body.priceRange {
    height: 100px;
  }

  .productSideBar .productSide li {
    width: 100%;
    display: block;
  }

  .productSideBar .productSide li a {
    padding: 7px 0;
    display: block;
    color: #222222;
  }

  .productSideBar .productSide li a i.fa {
    float: left;
    line-height: 20px;
    margin-left: 5px;
  }

  .productSideBar .productSide li a:hover, .productSideBar .productSide li a:focus {
    color: #ec5598;
    background-color: transparent;
  }

  .productSideBar .productSide li a:focus {
    color: #222222;
  }

  .productSideBar .productSide li ul li a {
    padding-right: 10px;
    color: #888888;
    font-size: 13px;
  }

  .price-slider-inner {
    display: block;
    margin: 15px 0 15px 0;
  }

  .price-slider-inner .amount-wrapper {
    position: absolute;
    bottom: 25px;
    right: 12px;
    font-size: 14px;
    width: 120px;
    color: #222222;
  }

  .price-slider-inner .amount-wrapper input {
    background-color: transparent;
    border: 0;
    width: 28%;
    font-size: 14px;
    color: #222222;
    font-weight: 700;
    text-align: left;
  }

  .price-slider-inner .ui-widget-content {
    background: none;
    border: 0;
    background-color: #f0f0f0;
    height: 4px;
    clear: both;
    border-radius: 0;
    margin: 0 7px;
  }

  .price-slider-inner .ui-widget-content .ui-slider-range {
    background-color: #ec5598;
    border: none;
    border-radius: 0;
  }

  .price-slider-inner .ui-widget-content .ui-slider-handle {
    border-radius: 50%;
    background: none;
    border: 3px solid #ec5598;
    background-color: #ffffff;
    top: -6px;
    width: 16px;
    height: 16px;
    outline: none;
  }

  .produtSingle {
    width: 100%;
    height: 430px;
    border: 1px solid #dedede;
    background-color: #ffffff;
  }

  @media (min-width: 320px) {
    .produtSingle {
      margin-bottom: 25px;
    }
  }

  @media (min-width: 768px) {
    .produtSingle {
      margin-bottom: 50px;
    }
  }

  .produtSingle:hover .produtImage img {
    transform: scale(1.2);
  }

  .produtSingle:hover .productMask {
    opacity: 1;
  }

  .productMask {
    width: 100%;
    height: 100%;
    background-color: rgba(236, 85, 152, 0.7);
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  .produtImage {
    width: 100%;
    height: 319px;
    text-align: center;
    line-height: 319px;
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
  }

  .produtImage img {
    display: inline-block;
    transition: all 0.8s ease-in-out;
  }

  .productOption {
    width: auto;
    height: 45px;
  }

  .productOption li a {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    border: 1px solid #ffffff;
    color: #ffffff;
    font-size: 17px;
    display: block;
  }

  .productCaption {
    text-align: center;
  }

  .productCaption h2 {
    margin: 0 0 7px 0;
  }

  .productCaption h2 a {
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
    color: #222222;
  }

  .productCaption h3 {
    font-weight: 700;
    color: #ec5598;
    font-size: 24px;
    margin: 0;
  }

  .productPagination.productPagination nav {
    border-top: none;
    margin: 0;
  }

  .productPagination.productPagination .pagination {
    margin: 0;
  }

  @media (min-width: 320px) {
    .productPagination.productPagination .pagination li {
      margin: 0 0 10px 10px;
    }
  }

  @media (min-width: 768px) {
    .productPagination.productPagination .pagination li {
      margin: 0 0 0 10px;
    }
  }

  .productPagination.productPagination .pagination li a {
    color: #282428;
  }

  .productPagination.productPagination .pagination li a:hover {
    color: #ffffff;
  }

  .productPagination.productPagination .pagination li.active a, .productPagination.productPagination .pagination li.active a:focus, .productPagination.productPagination .pagination li.active a:hover {
    color: #ffffff;
    background-color: #ec5598;
    border-color: #ec5598;
  }

  .price-range {
    border-top: 0 !important;
    border: 1px solid #dedede;
    padding-top: 27px;
    overflow-x: hidden;
  }

  .price-range-content {
    margin-bottom: 22px;
  }

  .price-text {
    margin-left: 10px;
  }

  .price-icon {
    margin-right: 2px;
    margin-left: 2px;
  }

  .price-text,
  .price-icon,
  .price-value {
    color: black;
  }

  .price-icon,
  .price-value {
    font-weight: bold;
  }

  .noUi-connect {
    background-color: #ec5598;
  }

  .noUi-target {
    background: #F0F0F0;
    border-radius: 0;
    border: none;
    box-shadow: none;
  }

  .noUi-horizontal {
    height: 4px;
  }

  .noUi-horizontal .noUi-handle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }

  .noUi-handle {
    cursor: e-resize;
    box-shadow: none;
    border: 3px solid #ec5598;
  }

  .noUi-handle:before, .noUi-handle:after {
    display: none;
  }

  html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
    left: -16px;
  }

  html:not([dir=rtl]) .noUi-horizontal .noUi-handle.noUi-handle-upper {
    left: 0;
  }

  /*=== 10. profile ===*/
  @media (min-width: 320px) {
    .profileArea {
      padding: 50px 0 25px 0;
    }
  }

  @media (min-width: 768px) {
    .profileArea {
      padding: 80px 0 100px 0;
    }
  }

  .profileImage {
    position: relative;
    border-radius: 100%;
    border: 7px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0 7px 21px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 320px) {
    .profileImage {
      width: 254px;
      height: 254px;
      margin: 0 auto 30px auto;
    }
  }

  @media (min-width: 768px) {
    .profileImage {
      width: 160px;
      height: 160px;
      margin: 0 auto;
    }
  }

  @media (min-width: 992px) {
    .profileImage {
      width: 200px;
      height: 200px;
    }
  }

  @media (min-width: 1200px) {
    .profileImage {
      width: 254px;
      height: 254px;
    }
  }

  .profileImage img {
    border-radius: 100%;
    max-width: 100%;
    height: 100%;
  }

  .profilePersonal {
    margin: 0 0 30px 0;
  }

  .profilePersonal h2 {
    margin: 0 0 15px 0;
    font-weight: 400;
  }

  .profilePersonal p {
    margin: 0 0 15px 0;
  }

  .profilePersonal .list-unstyled {
    margin-bottom: 10px;
  }

  .profilePersonal .list-unstyled li {
    position: relative;
    padding-right: 20px;
    line-height: 30px;
  }

  .profilePersonal .list-unstyled li i.fa {
    font-size: 14px;
    color: #ec5598;
    position: absolute;
    right: 0;
    left: 0;
    line-height: 30px;
  }

  .profilePersonal .list-unstyled li a {
    text-decoration: underline;
    line-height: 30px;
    color: #666666;
  }

  .profilePersonal .list-inline li a {
    font-size: 13px;
    color: #b7b7b7;
    line-height: 26px;
  }

  .profilePersonal .list-inline li a:hover {
    color: #666666;
  }

  .profileSkills {
    padding: 60px 30px 45px 30px;
    margin-bottom: 35px;
  }

  .progressSingle .list-inline li {
    line-height: 24px;
    margin-bottom: 6px;
  }

  .progressSingle .progress {
    height: 6px;
    margin-bottom: 15px;
    overflow: hidden;
    background-color: #d8d8d8;
    border-radius: 0;
    box-shadow: none;
  }

  .progressSingle .progress-bar {
    background-color: #ec5598;
    box-shadow: none;
  }

  .profileExtra {
    margin-bottom: 45px;
  }

  .extraTitle h3 {
    font-size: 20px;
    line-height: 22px;
    font-weight: 400;
  }

  .educationDetails dt {
    font-size: 15px;
    font-weight: 600;
    color: #222222;
    margin: 0 0 15px 0;
  }

  .educationDetails dd {
    line-height: 24px;
    margin: 0 0 24px 0;
  }

  @media (min-width: 320px) {
    .pageGallery .list-inline li {
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    .pageGallery .list-inline li {
      width: auto;
    }
  }

  .pageGallery .list-inline li a {
    display: block;
    text-align: center;
    line-height: 26px;
    color: #666666;
  }

  @media (min-width: 320px) {
    .pageGallery .list-inline li a {
      width: 100%;
      font-size: 20px;
      margin-bottom: 25px;
    }
  }

  @media (min-width: 768px) {
    .pageGallery .list-inline li a {
      width: 84px;
      font-size: 12px;
      margin-bottom: 0;
    }
  }

  @media (min-width: 992px) {
    .pageGallery .list-inline li a {
      width: 125px;
      font-size: 14px;
    }
  }

  @media (min-width: 1200px) {
    .pageGallery .list-inline li a {
      width: 134px;
      font-size: 14px;
    }
  }

  .pageGallery .list-inline li a span {
    width: 100%;
    margin-bottom: 15px;
    display: block;
  }

  @media (min-width: 320px) {
    .pageGallery .list-inline li a span {
      height: auto;
    }
  }

  @media (min-width: 768px) {
    .pageGallery .list-inline li a span {
      height: 93px;
    }
  }

  @media (min-width: 992px) {
    .pageGallery .list-inline li a span {
      height: 138px;
    }
  }

  @media (min-width: 320px) {
    .pageGallery .list-inline li a span img {
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    .pageGallery .list-inline li a span img {
      width: auto;
    }
  }

  @media (min-width: 768px) {
    .gallery-col .col-md:nth-of-type(1),
    .gallery-col .col-md:nth-of-type(2),
    .gallery-col .col-md:nth-of-type(3),
    .gallery-col .col-md:nth-of-type(4) {
      padding-left: 0;
    }
  }

  @media (min-width: 768px) {
    .gallery-col .col-md {
      flex-grow: .187;
    }
  }

  @media (min-width: 992px) {
    .gallery-col .col-md {
      flex-grow: 1;
    }
  }

  @media (min-width: 1200px) {
    .gallery-col .col-md {
      flex-grow: .175;
    }
  }

  .gallery-col .gallery-text {
    font-size: 20px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 25px;
  }

  @media (min-width: 768px) {
    .gallery-col .gallery-text {
      font-size: 12px;
      margin-bottom: 0;
    }
  }

  @media (min-width: 992px) {
    .gallery-col .gallery-text {
      font-size: 14px;
    }
  }

  .fancybox-overlay {
    background-color: rgba(236, 85, 152, 0.5);
    // background-image: none;
  }

  @media (min-width: 320px) {
    .adjustPadding {
      padding: 50px 0 0 0;
    }
  }

  @media (min-width: 768px) {
    .adjustPadding {
      padding: 50px 15px 0 0;
    }
  }

  /*================= 11. BLOG ==================*/
  /*================= 11.1 BLOG SINGLE ==================*/
  @media (min-width: 320px) {
    .blogSingle {
      padding: 50px 0;
    }
  }

  @media (min-width: 768px) {
    .blogSingle {
      padding: 100px 0;
    }
  }

  .singlePost {
    border-bottom: 1px dashed #b7b7b7;
  }

  .singlePost > img {
    display: block;
    width: 100%;
  }

  @media (min-width: 320px) {
    .singlePost {
      padding-bottom: 50px;
    }
  }

  @media (min-width: 768px) {
    .singlePost {
      padding-bottom: 95px;
    }
  }

  .blog .paginationCommon nav {
    border-top: none;
  }

  @media (min-width: 320px) {
    .blog .paginationCommon nav {
      margin: 0 0 70px 0;
    }
  }

  @media (min-width: 768px) {
    .blog .paginationCommon nav {
      margin: 0;
    }
  }

  .blog .paginationCommon .pagination {
    margin: 0;
  }

  .blog-fullwidth .blogPost img {
    display: block;
    width: 100%;
  }

  @media (min-width: 320px) {
    .blogPost {
      margin-bottom: 50px;
    }
  }

  @media (min-width: 768px) {
    .blogPost {
      margin-bottom: 100px;
    }
  }

  @media (min-width: 320px) {
    .blogPost img {
      margin-bottom: 20px;
    }
  }

  @media (min-width: 768px) {
    .blogPost img {
      margin-bottom: 35px;
    }
  }

  .blogPost h2 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  @media (min-width: 768px) {
    .blogPost h2 {
      font-size: 24px;
      margin-bottom: 25px;
    }
  }

  .blogPost h2 a {
    color: #ec5598;
  }

  .blogPost p img {
    float: left;
  }

  @media (min-width: 320px) {
    .blogPost p img {
      margin: 0 0 25px 0;
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    .blogPost p img {
      margin: 0 25px 0 0;
      width: auto;
    }
  }

  .blogPost .list-inline li a {
    color: #666666;
    margin: 0 0 5px 0;
  }

  .blogPost .list-inline li a i.fa {
    font-size: 13px;
    color: #cdcdcd;
    margin: 0 0 0 5px;
  }

  .blogPost .paginationCommon nav {
    border-top: none;
  }

  @media (min-width: 320px) {
    .blogPost .paginationCommon nav {
      margin: 0 0 20px 0;
    }
  }

  @media (min-width: 768px) {
    .blogPost .paginationCommon nav {
      margin: 0 0 70px 0;
    }
  }

  .blogPost .paginationCommon .pagination {
    margin: 0;
  }

  @media (min-width: 320px) {
    .blogCommnets {
      margin-bottom: 50px;
    }
  }

  @media (min-width: 768px) {
    .blogCommnets {
      margin-bottom: 100px;
    }
  }

  .blogCommnets h3 {
    font-size: 24px;
    margin-bottom: 35px;
    letter-spacing: 1px;
  }

  .blogCommnets .media {
    padding-bottom: 15px;
    border-bottom: 1px dashed #b7b7b7;
  }

  @media (min-width: 320px) {
    .blogCommnets .media {
      margin-top: 15px;
    }
  }

  @media (min-width: 768px) {
    .blogCommnets .media {
      margin-top: 30px;
    }
  }

  .blogCommnets .media > .media-left {
    display: -webkit-box;
    float: right;
    padding-left: 20px;
    width: 90px;
  }

  .blogCommnets .media > .media-left img {
    width: 70px;
  }

  .blogCommnets .media-body {
    width: 10000px;
    display: table-cell;
    vertical-align: top;
  }

  .blogCommnets .media-body h4 {
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 0 15px 0;
  }

  .blogCommnets .media-body h5 {
    color: #666666;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .blogCommnets .media-body h5 i.fa {
    color: #cdcdcd;
    margin-left: 7px;
  }

  .blogCommnets .media-body .btn-link {
    color: #ec5598;
    text-transform: capitalize;
  }

  @media (min-width: 768px) {
    .blogCommnets .mediaMargin {
      margin-right: 100px;
    }
  }

  .commentsForm h3 {
    font-size: 24px;
    margin-bottom: 35px;
    letter-spacing: 1px;
  }

  .commentsForm .btn-primary {
    width: 158px;
    text-align: center;
  }

  .form-half {
    width: 100%;
  }

  @media (min-width: 768px) {
    .form-half {
      width: 48%;
      display: inline-block;
    }
  }

  @media (min-width: 768px) {
    .form-left {
      margin-left: 4%;
    }
  }

  @media (min-width: 768px) {
    .form-right {
      float: left;
    }
  }

  .blogSidebar .input-group {
    margin-bottom: 30px;
  }

  .blogSidebar .input-group-addon {
    border-radius: 0;
    background-color: #ffffff;
    border: 1px solid #dedede;
    border-right: 0;
    padding: 9px 15px;
    color: #555;
  }

  .blogSidebar .form-control {
    border-left: 0;
  }

  .blogSidebar .panel {
    border-radius: 0;
    border: none;
    margin-bottom: 30px;
  }

  .blogSidebar .panel-heading {
    background-color: #ec5598;
    border-color: #ec5598;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    padding: 12px 15px;
    border-radius: 0;
  }

  .blogSidebar .panel-body {
    border: 1px solid #dedede;
    border-top: none;
    padding: 30px;
  }

  .blogSidebar .categoryList li a {
    padding: 14px 0;
    display: block;
    border-bottom: 1px dashed #b7b7b7;
    color: #666666;
  }

  .blogSidebar .categoryList li a:hover {
    color: #ec5598;
  }

  .blogSidebar .categoryList li:last-child a {
    border-bottom: none;
  }

  .blogSidebar .media {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px dashed #b7b7b7;
  }

  .blogSidebar .media .media-left {
    display: -webkit-box;
    float: right;
    padding-left: 20px;
    width: 100px;
  }

  @media (min-width: 768px) {
    .blogSidebar .media .media-left {
      padding-left: 15px;
      width: 80px;
    }
  }

  @media (min-width: 992px) {
    .blogSidebar .media .media-left {
      padding-left: 20px;
      width: 100px;
    }
  }

  .blogSidebar .media:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .blogSidebar .media-body {
    width: 10000px;
    display: table-cell;
    vertical-align: top;
  }

  .blogSidebar .media-body .media-heading {
    margin-bottom: 12px;
  }

  .blogSidebar .media-body .media-heading a {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 10px;
    color: #222222;
  }

  /*=== 15. USER PROFILE ===*/
  /*=== 15.1 COMMON PART ===*/
  .profile {
    padding: 30px 20px;
    border: 1px solid #dedede;
    border-top: none;
  }

  .innerWrapper {
    width: 100%;
    display: block;
  }

  .innerWrapper .alert {
    margin-bottom: 40px;
  }

  .innerWrapper .form-group button {
    width: 100%;
  }

  .orderBox {
    height: 56px;
    padding: 0 20px;
    line-height: 56px;
    font-weight: 400;
    text-transform: uppercase;
    color: #222222;
    font-size: 20px;
    margin-bottom: 30px;
  }

  .dashboard {
    margin-bottom: 50px;
  }

  .dashboard h4 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    color: #222222;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .dashboard.list-inline {
    display: block;
    margin-bottom: 50px;
  }

  .dashboard.list-inline li {
    padding: 0;
    display: block;
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    .dashboard.list-inline li {
      padding: 0 0 0 10px;
      display: inline-block;
      margin: 0;
    }
  }

  @media (min-width: 992px) {
    .dashboard.list-inline li {
      padding: 0 0 0 20px;
    }
  }

  .dashboard.list-inline li .btn-lg {
    background-color: #f0f0f0;
    color: #666666;
    font-weight: 700;
    transition: all 0.3s ease-in-out;
    width: 100%;
    padding: 47px 0;
    font-size: 16px;
  }

  @media (min-width: 768px) {
    .dashboard.list-inline li .btn-lg {
      width: 127px;
      padding: 31px 0;
      font-size: 14px;
    }
  }

  @media (min-width: 992px) {
    .dashboard.list-inline li .btn-lg {
      width: 167px;
      padding: 47px 0;
      font-size: 16px;
    }
  }

  @media (min-width: 1200px) {
    .dashboard.list-inline li .btn-lg {
      width: 203px;
      padding: 67px 0;
      font-size: 18px;
    }
  }

  .dashboard.list-inline li .btn-lg i {
    display: block;
    margin-bottom: 20px;
  }

  .dashboard.list-inline li .btn-lg:hover {
    background-color: #ec5598;
    color: #ffffff;
  }

  .dashboard.list-inline li:last-child {
    padding: 0;
  }

  .innerWrapper h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    color: #222222;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .innerWrapper h3 span {
    color: #ec5598;
  }

  .userSection .orderBox {
    margin-bottom: 0;
  }

  .innerWrapper .table-responsive {
    border: 1px solid #dedede;
    border-top: none;
    margin-bottom: 0;
  }

  @media (min-width: 480px) {
    .innerWrapper .table-responsive {
      overflow-x: auto;
    }
  }

  .innerWrapper .table-responsive .table {
    margin-bottom: 0;
  }

  .innerWrapper .table-responsive .table thead tr th {
    border: none;
    background-color: #ec5598;
    padding: 0;
    height: 55px;
    vertical-align: middle;
    text-align: right;
    font-size: 12px;
    color: #ffffff;
    padding: 10px 20px 10px 10px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: none;
    text-align: center;
  }

  @media (min-width: 768px) {
    .innerWrapper .table-responsive .table thead tr th {
      text-align: right;
      font-size: 15px;
    }
  }

  .innerWrapper .table-responsive .table thead tr th:last-child {
    border: none;
  }

  .innerWrapper .table-responsive .table tbody tr td {
    padding-right: 20px;
    font-weight: 400;
    vertical-align: middle;
  }

  .innerWrapper .table-responsive .table tbody tr td .btn-default {
    box-shadow: none;
    border-radius: 0;
    padding: 15px 20px;
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    color: #666666;
    border: 1px solid #dedede;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
  }

  .innerWrapper .table-responsive .table tbody tr td .btn-default:hover {
    background-color: #ec5598;
    color: #ffffff;
    border-color: #ec5598;
  }

  .innerWrapper .table-responsive .table tbody tr td:last-child {
    text-align: center;
    padding-right: 10px;
  }

  .innerWrapper .table-responsive .table tbody tr td:first-child {
    color: #ec5598;
  }

  .innerWrapper .table-responsive .table tbody tr td:first-child .fa {
    color: #eaeaea;
  }

  .innerWrapper .table-responsive .table tbody tr td .label {
    border-radius: 0;
    font-size: 12px;
  }

  .innerWrapper .table-responsive .table tbody tr td .btn-group, .innerWrapper .table-responsive .table tbody tr td .btn-group-vertical {
    display: -webkit-box;
  }

  .innerWrapper .table-responsive .table tbody th:first-child {
    color: #ec5598;
    font-weight: normal;
    vertical-align: middle;
    padding-right: 20px;
  }

  .innerWrapper .table-responsive .table td {
    vertical-align: middle;
    color: #666666;
    white-space: nowrap;
  }

  .innerWrapper .table-responsive .table th {
    white-space: nowrap;
  }

  /*=== 15.2 PROFILE ===*/
  .profile .orderBox {
    margin-bottom: 30px;
  }

  .profile .thumbnail {
    border: none;
    border-radius: 0;
    padding: 0;
    margin-bottom: 20px;
  }

  .profile .thumbnail img {
    width: 100%;
  }

  .profile .thumbnail .caption {
    padding: 20px 0 0 0;
  }

  @media (min-width: 480px) {
    .profile .form-horizontal .form-group {
      margin-bottom: 20px;
    }
  }

  @media (min-width: 768px) {
    .profile .form-horizontal .form-group {
      margin-bottom: 30px;
    }
  }

  .profile .form-horizontal .form-group .control-label {
    padding-top: 0;
    font-weight: 700;
  }

  @media (min-width: 480px) {
    .profile .form-horizontal .form-group .control-label {
      height: 25px;
      line-height: 25px;
    }
  }

  @media (min-width: 768px) {
    .profile .form-horizontal .form-group .control-label {
      height: 50px;
      line-height: 50px;
    }
  }

  .checkout .chooseOption {
    padding-right: 20px;
  }

  .checkout .control-label {
    font-weight: 700;
  }

  @media (min-width: 768px) {
    .checkout .control-label {
      height: 35px;
      line-height: 35px;
    }
  }

  .label-primary {
    background-color: #337ab7;
  }

  .label-success {
    background-color: #5cb85c;
  }

  .label-danger {
    background-color: #d9534f;
  }

  .label-info {
    background-color: #5bc0de;
  }

  .label-warning {
    background-color: #f0ad4e;
  }

  .label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
  }
}